import { ITabOptionsMobile } from 'shared/UI/TabsModileUI/TabsMobileUI';


export enum StorageKey {
  SHOWREVIEWS = 'SHOWREVIEWS',
  CURRENTTAB = 'CURRENTTAB'
}


export interface IAdminPanelState {
  isShowEditMoal: boolean
  [StorageKey.SHOWREVIEWS]: boolean
  [StorageKey.CURRENTTAB]: ITabOptionsMobile
}
