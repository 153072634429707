import EmployeeCards, { ETypeCardFrame } from './Frames/EmployeeCards';
import classes from './EmployeeModal.module.scss';
import EmployeeSelectCard from './Frames/EmployeeSelectCard';
import LockedReqMon from './Frames/LockedReqMon';
import SelectTypeofCard from './Frames/SelectTypeofCard';
import SetMbankCard from './Frames/SetMbankCard';
import ModalWindow from 'shared/UI/Modal';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  bankAccountSliceActions,
  getListBankAccounts,
} from 'reduxApi/slices/bankAccountSlice/model/bankAccountSlice';


interface EmployeeModalProps {
  open: boolean,
  setModal: Dispatch<SetStateAction<boolean>>
}

export enum ETabValues {
  EMPLOYEE_SELECT_CARD = 'EMPLOYEE_SELECT_CARD',
  SET_CARD = 'SET_CARD',
  EDIT_CARD = 'EDIT_CARD',
  FIRST_CREATE_CARD = 'FIRST_CREATE_CARD',
  EMPTY = 'EMPTY',
  LOCKED = 'LOCKED',
  SELECT_TYPE_OF_CARD = 'SELECT_TYPE_OF_CARD',
  MBANK = 'MBANK',
  EDIT_MBANK = 'EDIT_MBANK'
}

const EmployeeModal: FC<EmployeeModalProps> = (props) => {
  const { open, setModal } = props;
  const dispatch = useAppDispatch();

  const [ tab, setTab ] = useState<ETabValues>(ETabValues.EMPTY);
  const idEditedCard = useAppSelector(state => state.bankAccount.editedBankAccount);
  const bankAccounts = useAppSelector(state => state.bankAccount);


  useEffect(() => {
    (async() => {
      await dispatch(getListBankAccounts());
      dispatch(bankAccountSliceActions.handleIncHistory());
    })();
  }, []);

  const renderTab = () => {
    switch (tab) {
    case ETabValues.SELECT_TYPE_OF_CARD:
      return (<SelectTypeofCard setTab={setTab} />);
    case ETabValues.FIRST_CREATE_CARD:
      return (<SelectTypeofCard setTab={setTab}/>);
    case ETabValues.EMPLOYEE_SELECT_CARD:
      return (<EmployeeSelectCard setModal={setModal} setTab={setTab}/>);
    case ETabValues.MBANK:
      return (<SetMbankCard setTab={setTab}/>);
    case ETabValues.EDIT_MBANK:
      return (<SetMbankCard setTab={setTab} type={ETypeCardFrame.CHANGE} idCard={idEditedCard!}/>);
    case ETabValues.SET_CARD:
      return (<EmployeeCards setTab={setTab}/>);
    case ETabValues.EDIT_CARD:
      return (<EmployeeCards setTab={setTab} type={ETypeCardFrame.CHANGE} idCard={idEditedCard}/>);
    case ETabValues.LOCKED:
      return (<LockedReqMon setModal={setModal}/>);
    case ETabValues.EMPTY:
      return (<></>);
    default:
      return (<></>);
    }
  };

  useEffect(() => {
    (async () => {
      if (bankAccounts.bankAccountHistoryRequest >= 1) {
        if (bankAccounts.bankAccount.length === 0) {
          setTab(ETabValues.FIRST_CREATE_CARD);
        } else {
          setTab(ETabValues.EMPLOYEE_SELECT_CARD);
        }
      }
    })();
  }, [ bankAccounts.bankAccountHistoryRequest ]);


  return (
    <ModalWindow className={classes.modal} open={open} setIsModalOpen={setModal}>
      {renderTab()}
    </ModalWindow>
  );
};

export default EmployeeModal;
