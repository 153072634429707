import { IPartnersModel } from '../types/partnersType';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';
import { RootState } from 'reduxApi';


const name = 'partners';


const ENDPOINTS = { PARTNERS: '/api/v1/parners/' };


export const getPartners = createAsyncThunk(
  'name/getPartners',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.PARTNERS);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);


const initialState: IPartnersModel = {
  partnersStatus: REQUEST_STATUSES.NOT_REQUESTED,
  partners: [],
  partnersError: null,
};


const partnersSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getPartners, {
      status: 'partnersStatus',
      data: 'partners',
      error: 'partnersError',
    });
  },
});


export default partnersSlice;

export const partnersSelect = (state: RootState) => state[ name ];
