import { EmployerSchema, IParamEmployerRaed } from '../type/employersShema';
// eslint-disable-next-line import/named
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';
import pfp from 'shared/assets/pfp.png';


const name = 'employers';
interface IGetEmployeeArguments {
  filial?: string | undefined
  limit?: string | null | number
  offset?: string | null | number
  search?: string | null | number
  searchByCode?: string | null | number
  role?: string | null,
}

const ENDPOINTS = {
  EMPLOYERS_LIST: '/api/v1/employer/employers/',
  EMPLOYERS_READ: (id: string) => `/api/v1/employer/employers/${id}/`,
  COMMISSION: 'api/v1/payment/commision/',
  ROLES: '/api/v1/roles/',
  FILIAL: (id: string) => `/api/v1/place/filial/${id}/`,
};

export const getEmployerList = createAsyncThunk(
  `${name}/list`,
  async (argument: IGetEmployeeArguments, { extra: api }: any) => {
    try {

      const response = await api.get(ENDPOINTS.EMPLOYERS_LIST, {
        params: {
          limit: argument.limit,
          offset: argument.offset,
          search: argument.searchByCode,
          filial: argument.filial,
          role: argument.role,
        },
      });
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const getRolesByFilialId = createAsyncThunk(
  `${name}/getRolesByFilialId`,
  async (params: {filial_id?: string}, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.ROLES, { params });
      return response.data;
    } catch (e) {
      return e;
    }
  },
);
export const getFilialDetail = createAsyncThunk(
  `${name}/getFilialDetail`,
  async (pathId: string, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.FILIAL(pathId));
      return response.data;
    } catch (e) {
      return e;
    }
  },
);


export const getEmployerRead = createAsyncThunk(
  `${name}/read`,
  async (params: IParamEmployerRaed, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.EMPLOYERS_READ(params.id));
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const getDemoEmployerRead = createAsyncThunk(
  `${name}/read`,
  async () => {
    try {
      const response = {
        'id': '22b6bfd4-724c-4096-9a16-23b0020c721b',
        'created_at': '2023-06-17T00:11:07.733034+06:00',
        'type': 'employer',
        'full_name': 'Эльмурат ',
        'photo': pfp,
        'code': '158848',
        'dream': 'Коплю на путешествие',
        'rating': 0,
        'role': {
          'id': 'a7698d58-998c-4b3f-befa-ed9840b4c8ce',
          'title': 'Официант',
        },

      };
      return response;
    } catch (e) {
      return e;
    }
  },
);

export const getCommission = createAsyncThunk(
  `${name}/commission`,
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.COMMISSION);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);


const initialState: EmployerSchema = {
  employerListStatus: REQUEST_STATUSES.NOT_REQUESTED,
  employerList: {},
  employerListError: null,
  employerRead: {},
  commission: {},
  roles: [],
  filial: {},
};

export const employerSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearData(state, action: PayloadAction<keyof EmployerSchema >) {
      state[ action.payload ] = initialState[ action.payload ];
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getEmployerList, {
      status: 'employerListStatus',
      data: 'employerList',
      error: 'employerListError',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getEmployerRead, {
      status: 'employerReadStatus',
      data: 'employerRead',
      error: 'employerReadError',
    });
    addQueryCases(builder, getCommission, {
      status: 'commissionStatus',
      data: 'commission',
      error: 'commissionError',
    });
    addQueryCases(builder, getRolesByFilialId, {
      status: '',
      data: 'roles',
      error: '',
    });
    addQueryCases(builder, getFilialDetail, {
      status: '',
      data: 'filial',
      error: '',
    });
  },
});


export const { actions: employerActions } = employerSlice;
export const { reducer: employerReducer } = employerSlice;
