import classes from '../Gallery.module.scss';
import Thumb, { sizeWidthPhotoThumb } from '../Thumb/Thumb';
import classNames from 'classnames';
import {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  createRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import arrow from 'shared/assets/icons/arrowForward.svg';


interface NavigationProps {
  images?: string[],
  currentImage: number,
  setCurrentImage: Dispatch<SetStateAction<number | undefined>>,
  handleClose: () => void,
}
const scrollPhoto = (previews: RefObject<HTMLDivElement>, i: number) => {
  if (previews.current) {

    const countPhotos = Math.floor(previews.current.clientWidth / sizeWidthPhotoThumb);
    previews.current.scroll({
      left: (i - Math.floor(countPhotos / 2)) * sizeWidthPhotoThumb,
      behavior: 'smooth',
    });
  }

};
const Navigation: FC<NavigationProps> = (props) => {
  const { handleClose, images, currentImage, setCurrentImage } = props;
  const previews = createRef<HTMLDivElement>();

  const [ keyTrigger, setKeyTrigger ] = useState<{right?: boolean, left?: boolean}>({});

  const handleClick = useCallback((i: number) => {
    if (!images?.length) return;
    if (i < 0) {
      setCurrentImage(images.length - 1);
      scrollPhoto(previews, images.length - 1);
      return;
    }
    else if (i >= (images.length)) {
      setCurrentImage(0);
      scrollPhoto(previews, 0);
      return;
    }
    setCurrentImage(i);

    scrollPhoto(previews, i);

  }, [ previews ]);

  const listenerScroll = (e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      setKeyTrigger({ right: true });

    } else if (e.key === 'ArrowLeft') {
      setKeyTrigger({ left: true });
    } else if (e.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    scrollPhoto(previews, currentImage);

    document.body.addEventListener('keydown', listenerScroll);
    return () => document.body.removeEventListener('keydown', listenerScroll);
  }, []);

  useEffect(() => {
    if (keyTrigger.right === true) {
      handleClick(currentImage + 1);
    } else if (keyTrigger.left === true) {
      handleClick(currentImage - 1);
    }
  }, [ keyTrigger ]);

  return (
    <div className={classes.navigation}>
      <button
        onClick={() => handleClick(currentImage - 1)}
        className={classNames(classes.navigationButton, classes.prev)}
      >
        <img alt={''} src={arrow} width={16} height={16}/>
      </button>

      <div
        ref={previews}
        className={classes.thumbs}
      >
        {
          images?.map((item, i) =>
            <Thumb
              key={item}
              selected={i === currentImage}
              img={item}
              onClick={handleClick}
              index={i}
            />)
        }
      </div>
      <button
        className={classes.navigationButton}
        onClick={() => handleClick(currentImage + 1)}
      >
        <img
          alt={''}
          src={arrow} width={16} height={16}
        />

      </button>
    </div>
  );
};

export default memo(Navigation);
