import classes from './CustomButton.module.scss';
import { FC, ReactNode, memo, useState } from 'react';


enum eventTypes {
  clicked = 'clicked'
}

export type colorTypes = 'black' | 'purple' | 'gradient_purple' | 'secondary_purple' | 'white' | 'pink';
export type variantTypes = 'outlined' | 'text' | 'contained';


interface CustomButtonProps {
  children: ReactNode,
  color?: colorTypes,
  variant: variantTypes,
  classname?: string | undefined,
  [key: string]: unknown,
}
const CustomButton: FC<CustomButtonProps> = (props) => {
  const { children, color, variant, classname, ...prop } = props;
  const defaultDynamicClass = `${color}_${variant}`;
  const [ dynamicClass, setDynamicClass ] = useState(classes[ defaultDynamicClass ]);

  return (
    <button
      className={`${classes.button} ${dynamicClass} ${classname ? classname : null}`}
      {...prop}
      onMouseDown={() => setDynamicClass(prev => `${prev} ${classes[ eventTypes.clicked ]}`)}
      onMouseUp={() => {
        setTimeout(() => {
          setDynamicClass(classes[ defaultDynamicClass ]);
        }, 100);
      }}
    >
      {children}
    </button>
  );
};

export default memo(CustomButton);
