import classes from './SetMbankCard.module.scss';
import { ETypeCardFrame, ETypesOfCard } from './EmployeeCards';
import { ETabValues } from '../EmployeeModal';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import GreenMbank from 'shared/assets/icons/GreenMbank.svg';
import CustomInput from 'shared/UI/CustomInput/CustomInput';
import CustomButton from 'shared/UI/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import {
  patchPartialUpdateBankAccount,
  postCreateBankAccount,
} from 'reduxApi/slices/bankAccountSlice/model/bankAccountSlice';
import { toast } from 'react-toastify';
import { useDebounce } from 'usehooks-ts';


interface ISetMbankCard {
  setTab: Dispatch<SetStateAction<ETabValues>>,
  type?: ETypeCardFrame
  idCard?: string
}

export const PHONE_LENGTH = 13;
export const PHONE_LENGTH_MASK = 23;

const RESPONSES = {
  exist: 'already_exist',
  success: 'CREATED',
};

const SetMbankCard: FC<ISetMbankCard> = ({ setTab, type = ETypeCardFrame.CREATE, idCard }) => {
  const dispatch = useAppDispatch();
  const [ phone, setPhone ] = useState<string>('');
  const [ isError, setIsError ] = useState<boolean>(false);
  const [ isFocused, setIsFocused ] = useState<boolean>(false);
  const debouncedError = useDebounce(phone, 500);
  const bankAccounts = useAppSelector(state => state.bankAccount.bankAccount);
  const userPhone = useAppSelector(state => state.employee.employee.phone);

  const oldPhone = bankAccounts.find(item => item.id === idCard);

  useEffect(() => {
    if (isFocused && phone.length < PHONE_LENGTH) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [ debouncedError, isFocused ]);

  function unformatPhoneNumber(formattedPhoneNumber: string): string {
    const cleaned = formattedPhoneNumber.replace(/\D/g, '');
    return '+' + cleaned;
  }

  useEffect(() => {
    if (type === ETypeCardFrame.CREATE) {
      setPhone(unformatPhoneNumber(userPhone || '+996'));
    } else {
      setPhone('+' + oldPhone?.phone);
    }
  }, []);

  const handleSubmit = async () => {
    if (phone.length === PHONE_LENGTH) {
      if (type === ETypeCardFrame.CREATE) {
        const { payload } = await dispatch(postCreateBankAccount({
          type: ETypesOfCard.MBANK,
          phone: phone.replace('+', ''),
        }));
        if (payload.message === RESPONSES.success) {
          setTab(ETabValues.EMPLOYEE_SELECT_CARD);
        } else if (payload.response.data.status === RESPONSES.exist) {
          toast.error('Карта уже была зарегистрирована');
        }
      } else {
        const { payload } = await dispatch(patchPartialUpdateBankAccount({
          phone: phone.replace('+', ''),
          id: idCard,
        }));
        if (payload?.phone === phone.replace('+', '')) {
          setTab(ETabValues.EMPLOYEE_SELECT_CARD);
        } else {
          toast.error('Что-то пошло не так...');
        }
      }
    } else {
      toast.error('Введите номер телефона');
    }
  };

  return (
    <div>
      <div className={classes.title}>
        {type === ETypeCardFrame.CREATE
          ?
          'Добавление счета'
          :
          'Редактирование счета'
        }
      </div>
      <div className={classes.main}>
        <div className={classes.logo}>
          <img src={GreenMbank} alt="Мбанк" />
        </div>
        <CustomInput
          value={phone}
          error={isError}
          onFocus={() => setIsFocused(true)}
          onBlur={(() => setIsFocused(false))}
          placeholder='Введите номер телефона'
          onChange={(e) => {
            if (e.target.value.length <= PHONE_LENGTH) {
              setPhone(e.target.value);
            }
          }}
        />
      </div>
      <div className={classes.actionButtons}>
        <CustomButton
          classname={classes.cancel}
          color={'black'}
          variant={'outlined'}
          onClick={() => setTab(ETabValues.EMPLOYEE_SELECT_CARD)}
        >
            Назад
        </CustomButton>
        <CustomButton
          classname={classes.add}
          variant='contained'
          color='black'
          onClick={handleSubmit}
        >
          {type === ETypeCardFrame.CREATE
            ?
            'Добавить'
            :
            'Готово'
          }
        </CustomButton>
      </div>
    </div>
  );
};
export default SetMbankCard;
