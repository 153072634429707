import { store } from './reduxApi';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import React, { Suspense } from 'react';
import { lazily } from 'react-lazily';
import LoaderComponent from 'shared/UI/Loader/LoaderComponent';
import './app/i18n/i18n';


const { BrowserRouter } = lazily(() => import('react-router-dom'));
const App = React.lazy(() => import('./app/App')) ;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Suspense fallback={
    <div style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}><LoaderComponent/> </div>}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
  ,
);
