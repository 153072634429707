import { IInitialStateRequestMoneyForm } from '../type/requestMoneyFormSlice';
// eslint-disable-next-line import/named
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setSeparatorNum } from 'shared/common/constants';
import { IDataParamsBankAccount, IFormRequestMoney } from 'reduxApi/slices/bankAccountSlice/type/bankAccountType';


const name = 'requestMoneyForm';
const initialState: IInitialStateRequestMoneyForm = {
  cardData: {
    full_name: {
      placeholder: 'ФИО',
      value: '',
      error: false,
      regExp: '.',
    },
    inn: {
      placeholder: 'ИНН',
      value: '',
      error: false,
      regExp: '.',
    },
    bank_code: {
      placeholder: 'БИК Банка',
      value: '',
      error: false,
      regExp: '.',
    },
    account_number: {
      placeholder: 'Номер счета',
      value: '',
      error: false,
      regExp: '^.{16}$',
    },
    type: {
      placeholder: '1',
      value: '',
      error: false,
      regExp: '',
    },
    phone: {
      placeholder: '',
      value: '',
      error: false,
      regExp: '',
    },
  },
  isCardSelected: false,
  amount: {
    value: '',
    error: false,
    placeholder: 'Сумма вывода',
  },
};


interface IActionPayloadChange {
  value: string,
  key: keyof IFormRequestMoney,
  balance?: number,
}


const handleChangeForm = (state: IInitialStateRequestMoneyForm, action: IActionPayloadChange) => {
  state.cardData[ action.key ].value = action.value;
  state.cardData[ action.key ].error = !(new RegExp(state.cardData[ action.key ].regExp).test(action.value));
};

export const requestMoneyFormSlice = createSlice({
  name: `${name}/requestMoneyFormSlice`,
  initialState,
  reducers: {
    handleChange(state, action: PayloadAction<IActionPayloadChange>) {
      if (action.payload.key === 'account_number') {
        if (/^[0-9\s]+$/.test(action.payload.value) || action.payload.value === '') {
          handleChangeForm(state, {
            value: action.payload.value,
            key: action.payload.key,
          });
        }
      }
      else {
        handleChangeForm(state, {
          value: action.payload.value,
          key: action.payload.key,
        });
      }
    },
    handleChangeAmount(state: IInitialStateRequestMoneyForm, action: PayloadAction<IActionPayloadChange>) {
      if (
        (/^[0-9\s]+$/.test(action.payload.value)
        ||
        (action.payload.value === ''))
        &&
        ((+action.payload.value.replace(/\s/g, '')) <= (action.payload.balance ?? Infinity))
      ) {
        state.amount.value = setSeparatorNum(action.payload.value.replace(/\s/g, '')) || '';
        state.amount.placeholder = 'Сумма вывода';
        state.amount.error = false;

      } else if (+action.payload.value.replace(/\s/g, '') > (action.payload.balance ?? Infinity)
      || (+action.payload.value.replace(/\s/g, '') === 0)) {
        state.amount.error = true;
        state.amount.placeholder = 'Недоступная сумма';
      }
    },
    handleSelectFullAmount(state, action: PayloadAction<string>) {
      state.amount.value = action.payload;
      state.amount.error = false;
    },
    handleReplaceValues(state: IInitialStateRequestMoneyForm, action: PayloadAction<IDataParamsBankAccount>) {
      Object.keys(state.cardData).forEach(item => {
        if (action.payload?.[ item ]) {
          state.cardData[ item ].value = action.payload?.[ item ];
          state.cardData[ item ].error = false;
        }
      });
      state.isCardSelected = true;
    },
    handleCardIsNotSelected(state: IInitialStateRequestMoneyForm) {
      state.isCardSelected = false;
    },
  },
});

export const { actions: requestMoneyFormActions } = requestMoneyFormSlice;
export const { reducer: requestMoneyFormReducer } = requestMoneyFormSlice;
