import {
  IActionChangeEditEmployee,
  IFormEditEmployee,
  IInitialStateEditEmployeeType,
  IPostCheckOldPasswordData,
} from '../type/editEmployeeType';
import { MAX_LENGTH_TEXT_AREA } from 'components/Dashboards/Employee/EmployeeDream/EmployeeDream';
// eslint-disable-next-line import/named
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const name = 'editEmployee';
const ENDPOINTS = { CHECK_PASSWORD: '/api/v1/employer/passwords/check-password/' };
const initialState: IInitialStateEditEmployeeType = {
  form: {
    photo: { value: null },
    full_name: {
      value: '',
      placeholder: 'ФИО',
      error: false,
    },
    dream: {
      value: '',
      placeholder: 'Мечта',
      error: false,
    },
    phone: {
      value: '',
      placeholder: 'Номер телефона МБанк',
      error: false,
    },
  },
};

export const postCheckOldPassword = createAsyncThunk(
  `${name}/postCheckOldPassword`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IPostCheckOldPasswordData, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.CHECK_PASSWORD, data);
      return response.data;
    } catch (e: any) {
      return e.response.data;
    }
  },
);


export const editEmployeeSlice = createSlice({
  name: `${name}/editEmployeeSlice`,
  initialState,
  reducers: {
    handleWriteData(state, action) {
      if (Object.keys(action?.payload)?.length !== 0) {
        Object.keys(state.form).forEach((item: keyof IFormEditEmployee) => {
          state.form[ item ] = {
            ...state.form[ item ],
            value: action.payload[ item ] || '',
            error: false,
          };
        });
      }
    },
    handleChange(state: IInitialStateEditEmployeeType, action: PayloadAction<IActionChangeEditEmployee>) {
      if (action.payload.key === 'dream') {
        state.form[ action.payload.key ].value = action.payload.value.slice(0, MAX_LENGTH_TEXT_AREA);
        state.form[ action.payload.key ].error = !(!!(state.form[ action.payload.key ].value));
      }
      else {
        state.form[ action.payload.key ].value = action.payload.value;
        state.form[ action.payload.key ].error = !(!!(state.form[ action.payload.key ].value));
      }
    },
    setImgResult(state, action: PayloadAction<string | null>) {
      state.form.photo.value = action.payload;
    },
  },
});

export const { actions: editEmployeeActions } = editEmployeeSlice;
export const { reducer: editEmployeeReducer } = editEmployeeSlice;
