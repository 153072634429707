import classes from './GetRatingEmoji.module.scss';
import starIcon from 'shared/assets/icons/starIcon.svg';
import { FC } from 'react';
import { EEmojiTypes } from 'shared/common/constants';


interface GetRatingEmojiProps {
  rating: EEmojiTypes
}

const GetRatingEmoji: FC<GetRatingEmojiProps> = (props) => {
  const { rating } = props;

  return (
    <div className={classes.container}>
      <img src={starIcon} alt="Звезда" />
      <p>{rating} / 5</p>
    </div>
  );
};

export default GetRatingEmoji;
