import classes from './SelectTypeofCard.module.scss';
import { ETabValues } from '../EmployeeModal';
import { Button } from 'antd';
import WhiteMbank from 'shared/assets/icons/WhiteMbank.svg';
import { Dispatch, FC, SetStateAction } from 'react';
import CustomButton from 'shared/UI/CustomButton/CustomButton';


interface ISelectTypeofCard {
  setTab: Dispatch<SetStateAction<ETabValues>>,
}

const SelectTypeofCard: FC<ISelectTypeofCard> = ({ setTab }) => {
  return (
    <div>
      <div className={classes.title}>
        <h2>Добавление счёта</h2>
      </div>
      <div className={classes.main}>
        <div className={classes.types}>
          <Button className={classes.mbank} onClick={() => setTab(ETabValues.MBANK)}>
            <img src={WhiteMbank} alt="Мбанк" />
          </Button>
          <span className={classes.or}>Или</span>
          <Button className={classes.other} onClick={() => setTab(ETabValues.SET_CARD)}>
          Банковский счёт
          </Button>
        </div>
      </div>
      <div className={classes.actionButtons}>
        <CustomButton
          classname={classes.cancel}
          color={'black'}
          variant={'outlined'}
          onClick={() => setTab(ETabValues.EMPLOYEE_SELECT_CARD)}
        >
            Назад
        </CustomButton>
      </div>
    </div>
  );
};
export default SelectTypeofCard;
