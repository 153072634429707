import classes from './LockedQReqMon.module.scss';
import { FC, Dispatch, SetStateAction } from 'react';
import locked from 'shared/assets/icons/locked.svg';
import CustomButton from 'shared/UI/CustomButton/CustomButton';


interface IPropsLockedReqMon {
  setModal: Dispatch<SetStateAction<boolean>>,
}

const LockedReqMon:FC<IPropsLockedReqMon> = (props) => {
  const { setModal } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}><img src={locked} alt='' /><p>Запрос в обработке</p></div>
      <p className={classes.description}>Ваш предыдущий запрос на вывод находится в обработке.</p>
      <CustomButton color='purple' variant='contained' onClick={() => setModal(false)}>Подождать</CustomButton>
    </div>
  );
};

export default LockedReqMon;
