import en from './translations/en.json';
import ru from './translations/ru.json';
import ky from './translations/ky.json';
import { LANGUAGE_LOCAL } from 'shared/common/constants';
import { ELanguages } from 'components/Payment/ChangeLanguage/ChangeLanguage';
import i18next from 'i18next';


const currentLang: ELanguages = (localStorage.getItem(LANGUAGE_LOCAL) as ELanguages) || ELanguages.ru;


(i18next as any).init({
  defaultNS: currentLang,
  fallbackLng: currentLang,
  debug: false,
  interpolation: { escapeValue: false },
  resources: {
    en: { en: en },
    ru: { ru: ru },
    ky: { ky: ky },
  },
},
);

export default i18next;
