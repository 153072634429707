/* eslint-disable import/named */
import { IEmployeeList } from '../type/employeeListSchema';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';
import { ParamVariants } from 'components/Dashboards/Admin/EmployeeBlock/EmployeeBlock';
import { RootState } from 'reduxApi';


const name = 'employeeList';

const ENDPOINTS = {
  EMPLOYEELIST: '/api/v1/place-admin/employer/',
  ROLES: '/api/v1/roles/',
  PROFILE: '/api/v1/place-admin/profile/',
};

interface IGetEmployeeArguments {
  filial: string | undefined;
  limit?: string | null | number;
  offset?: string | null | number;
  orderByRating?: ParamVariants | string | null;
  orderByName?: ParamVariants | string | null;
  search?: string | null | undefined;
}
interface IGetEmployeeSort {
  orderByRating?: ParamVariants | string;
  orderByName?: ParamVariants | string;
  search?: string;
}

interface ICreateUser {
  role?: string | undefined;
  full_name?: string | undefined;
  photo?: string | undefined;
  password?: string | undefined;
  id?: string | undefined;
  confirm?: string | undefined;
  confirm_password?: string | undefined;
}

interface IEditParams {
  id: string | undefined;
  data: ICreateUser;
}

type IChangePasswordPick = Pick<ICreateUser, 'confirm' | 'confirm_password'>;

interface IChangePasswordParams {
  id: string | undefined;
  data: IChangePasswordPick;
}

export const getEmployeeList = createAsyncThunk(
  `${name}/getEmployeeList`,
  async ({ filial, ...params }: IGetEmployeeArguments, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.EMPLOYEELIST, {
        params,
        data: filial,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const createEmployee = createAsyncThunk(
  `${name}/createEmployee`,
  async (data: ICreateUser, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.EMPLOYEELIST + 'create/', data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);
export const deleteEmployee = createAsyncThunk(
  `${name}/deleteEmployee`,
  async (id: string | undefined, { extra: api }: any) => {
    try {
      const response = await api.delete(
        `${ENDPOINTS.EMPLOYEELIST}/delete/${id}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const getRoles = createAsyncThunk(
  `${name}/getRoles`,
  async (params: string, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.ROLES, { params: { place_type: params } });
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const getEmployeeListFilter = createAsyncThunk(
  `${name}/getFilter`,
  async (params: IGetEmployeeSort, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.EMPLOYEELIST, { params });
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const editEmployee = createAsyncThunk(
  `${name}/editEmployee`,
  async ({ id, data }: IEditParams, { extra: api }: any) => {
    try {
      const response = await api.patch(`${ENDPOINTS.EMPLOYEELIST}${id}/`, { ...data });
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const changePassword = createAsyncThunk(
  `${name}/changePassword`,
  async ({ id, data }: IChangePasswordParams, { extra: api }: any) => {
    try {
      const response = await api.post(
        `${ENDPOINTS.EMPLOYEELIST}${id}/change-password/`,
        { ...data },
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const getProfileAdmin = createAsyncThunk(
  `${name}/getProfileAdmin`,
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.PROFILE);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

const initialState: IEmployeeList = {
  employeeListStatus: REQUEST_STATUSES.NOT_REQUESTED,
  actionsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  employeeList: {},
  selectedEmployee: null,
  selectUser: {
    type: '',
    userInfo: {},
  },
  createUser: null,
  admin: {},
  roles: [],
  employeeListError: null,
  detailUser: {},
};

const employeeListSlice = createSlice({
  name,
  initialState,
  reducers: {
    selectReducer(state, action) {
      state.selectUser = action.payload;
    },
    clearData(state, action: PayloadAction<keyof IEmployeeList>) {
      state[ action.payload ] = initialState[ action.payload ];
    },
    createData(state, action) {
      state.createUser = action.payload;
    },
    setSelectedEmployee(state, action) {
      state.selectedEmployee = action.payload;
    },
    setDetailUser(state, action) {
      state.detailUser = action.payload;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getEmployeeList, {
      status: 'employeeListStatus',
      data: 'employeeList',
      error: 'employeeListError',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getEmployeeListFilter, {
      status: 'employeeListStatus',
      data: 'employeeList',
      error: 'employeeListError',
      options: { concat: false },
    });
    addQueryCases(builder, getRoles, {
      status: 'employeeListStatus',
      data: 'roles',
      error: 'employeeListError',
    });
    addQueryCases(builder, getProfileAdmin, {
      status: 'employeeListStatus',
      data: 'admin',
      error: 'employeeListError',
    });
    addQueryCases(builder, createEmployee, {
      status: 'actionsStatus',
      data: '',
      error: 'employeeListError',
    });
    addQueryCases(builder, editEmployee, {
      status: 'actionsStatus',
      data: '',
      error: 'employeeListError',
    });
    addQueryCases(builder, changePassword, {
      status: 'actionsStatus',
      data: '',
      error: 'employeeListError',
    });
  },
});

export const { actions: employeeListAction } = employeeListSlice;
export const { reducer: employeeListReducer } = employeeListSlice;
export default employeeListSlice;

export const employeeSelect = (state: RootState) => state[ name ];
