/* eslint-disable max-len */
import classes from './TabsFilter.module.scss';
import { ITabOptions } from '../../../components/Dashboards/Admin/ReviewTipsOnBlock/ReviewTipsOnBlock';
import { Arrow, Directions } from '../Arrow/Arrow';
import { useState } from 'react';


interface ITabsFilterProps<T> {
  options: ITabOptions<T>[],
  currentOption: ITabOptions<T>;
  handleSelect: (value: T) => void;
}

function TabsFilter<T>({ options, handleSelect, currentOption, ...props }: ITabsFilterProps<T>) {
  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const handleToggleMenu = () => {
    setIsOpen(prev => !prev);
  };

  const handleChangeOption = (option: ITabOptions<T>) => {
    handleToggleMenu();
    handleSelect(option.value);
  };

  return (
    <>
      <div className={
        `
          ${classes.container}
          ${isOpen && classes.active}
        `
      } {...props}>
        <div onClick={handleToggleMenu} className={`${classes.toggle} ${classes.click}`}>
          <p>{currentOption.title}</p>
          <Arrow direction={isOpen ? Directions.up : Directions.down}/>
        </div>
        {isOpen && <div className={classes.menu}>
          <ul>
            {!!options.length && options.filter(option => option.id !== currentOption.id).map(option => (
              <li
                onClick={() => handleChangeOption(option)}
                key={option.title}
              >{option.title}</li>
            ))}
          </ul>
        </div>}
      </div>
      {isOpen && <div onClick={handleToggleMenu} className={classes.activeSelect}/>}
    </>
  );
}

export default TabsFilter;
