import classes from './CustomInput.module.scss';
import React, { FC, InputHTMLAttributes, ReactNode, useState, useEffect, cloneElement, ReactElement } from 'react';


enum eventTypes {
  focus = 'focus',
  valued = 'valued'
}
type CustomInputMouseEvent = Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder'>
interface CustomInputProps extends CustomInputMouseEvent {
  afterSuffix?: ReactNode,
  value: string | undefined,
  beforeSuffix?: ReactNode,
  classNameContainer?: string,
  classNameInput?: string,
  error?: boolean | (() => boolean),
  placeholder: string | ReactNode
}

const idInput = `CustomInput_${Date.now()}`;

const CustomInput: FC<CustomInputProps> = (props) => {
  const {
    disabled,
    placeholder,
    classNameInput,
    classNameContainer,
    afterSuffix,
    beforeSuffix,
    value,
    error,
    ...prop
  } = props;

  const defaultDynamicClass = `
  ${afterSuffix ? classes.hasAfterSuffix : ''}
  ${beforeSuffix ? classes.hasBeforeSuffix : ''}
  ${(typeof error === 'boolean' ? error : error?.()) ? classes.error : ''}
  ${disabled ? classes.disabled : ''}
  `;

  const [ dynamicClass, setDynamicClass ] = useState(defaultDynamicClass);
  const getPlaceholderClassName = (): string => {
    if (value) {
      if (dynamicClass.includes(eventTypes.focus)) {
        return classes.active;
      } else {
        return `${classes.topped} ${classes.active}`;
      }
    } else {
      return classes.active;
    }
  };

  useEffect(() => {
    setDynamicClass(defaultDynamicClass);
  }, [ error, value ]);

  return (
    <div className={`${classes.container} ${dynamicClass} ${classNameContainer ? classNameContainer : null}`}>
      {
        placeholder
        &&
        <label htmlFor={idInput} className={`
          ${classes.placeholder}
          ${getPlaceholderClassName()}
          `}
        >
          {
            typeof placeholder === 'string'
              ?
              placeholder
              :
              cloneElement(placeholder as ReactElement)
          }
        </label>
      }
      {/*!DANGER! DO NOT WRITE REACT NODE (ELEMENTS) IN THIS SPACE */}
      <input
        disabled={disabled}
        id={idInput}
        onFocus={() => {
          setDynamicClass(prev => `${prev} ${classes[ eventTypes.focus ]}`);
        }}
        onBlur={() => {
          setDynamicClass(defaultDynamicClass);
        }}
        value={value}
        className={`${classes.input} ${classNameInput ? classNameInput : null}`}
        {...prop}
      />
      <div className={classes.beforeSuffix}>
        {
          beforeSuffix
        }
      </div>
      <div className={classes.afterSuffix}>
        {
          afterSuffix
        }
      </div>
    </div>
  );
};

export default CustomInput;
