import classes from './arrow.module.scss';


interface IArrow {
  direction?: Directions;
}

export enum Directions {
  up = 'up',
  down = 'down',
}

export function Arrow ({ direction = Directions.down }: IArrow) {
  return (
    <div className={classes[ direction ]}>
      <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5L0 0H10L5 5Z" fill="#1C1B1F"/>
      </svg>
    </div>
  );
}
