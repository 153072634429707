import {
  IDataParamsBankAccount,
  IDataParamsRequestMoney,
  IInitialStateRequestMoney,
} from '../type/bankAccountType';
// eslint-disable-next-line import/named
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addQueryCases, logOut } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';


const name = 'bankAccount';


const ENDPOINTS = {
  CREATE_REUQEST_MONEY: '/api/v1/employer/request_money/',
  CREATE_REUQEST_MONEY_STATUS: '/api/v1/employer/request_money/status/',
  BANK_ACCOUNT: '/api/v1/employer/bank_accounts/',
};


export const postRequestMoney = createAsyncThunk(
  `${name}/postRequestMoney`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IDataParamsRequestMoney, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.CREATE_REUQEST_MONEY, data);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const getRequestMoneyStatus = createAsyncThunk(
  `${name}/getRequestMoneyStatus`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.CREATE_REUQEST_MONEY);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const postCreateBankAccount = createAsyncThunk(
  `${name}/postCreateBankAccount`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IDataParamsBankAccount, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.BANK_ACCOUNT, data);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const patchPartialUpdateBankAccount = createAsyncThunk(
  `${name}/patchPartialUpdateBankAccount`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IDataParamsBankAccount, { extra: api }: any) => {
    try {
      const response = await api.patch(`${ENDPOINTS.BANK_ACCOUNT}${data.id}/`, data);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const getListBankAccounts = createAsyncThunk(
  `${name}/getListBankAccounts`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.BANK_ACCOUNT);
      return response.data;
    } catch (e: any) {
      if (e.response?.data?.detail === 'У вас недостаточно прав для выполнения данного действия.') {
        logOut();
      }
      return [];
    }
  },
);


const initialState: IInitialStateRequestMoney = {
  bankAccountStatus: REQUEST_STATUSES.NOT_REQUESTED,
  bankAccount: [],
  bankAccountError: null,
  bankAccountHistoryRequest: 0,

  editedBankAccount: null,
  status: null,
};


const bankAccountSlice = createSlice({
  name,
  initialState,
  reducers: {
    handleEdit(state, action: PayloadAction<string>) {
      state.editedBankAccount = action.payload;
    },
    handleIncHistory(state) {
      state.bankAccountHistoryRequest++;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getListBankAccounts, {
      status: 'bankAccountStatus',
      data: 'bankAccount',
      error: 'bankAccountError',
    });
    addQueryCases(builder, postCreateBankAccount, {
      status: 'bankAccountStatus',
      data: '',
      error: 'bankAccountError',
    });
    addQueryCases(builder, postRequestMoney, {
      status: 'bankAccountStatus',
      data: '',
      error: 'bankAccountError',
    });
    addQueryCases(builder, getRequestMoneyStatus, {
      status: '',
      data: 'status',
      error: '',
    });
  },
});

export const { actions: bankAccountSliceActions } = bankAccountSlice;
export const { reducer: bankAccountSliceReducer } = bankAccountSlice;

export default bankAccountSlice;
