import { IReviewsAndTips } from '../type/reviewsAndTipsSchema';
// eslint-disable-next-line import/named
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';


const name = 'reviewAndTips';

let isFirstTime = true;

const ENDPOINTS = { GET_REVIEWS: '/api/v1/place-admin/review/', GET_TIPS: '/api/v1/place-admin/tips/' };


interface IParams {
  limit: number | undefined
  offset: number | null | undefined
  reviewType?: string
  employeeCode?: string
}

export const getReviews = createAsyncThunk(
  `${name}/getReviews`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IParams, { extra: api, dispatch }: any) => {
    try {
      const response = await api.get(ENDPOINTS.GET_REVIEWS, { params });

      if (isFirstTime) {
        dispatch(reviewAndTipsAction.setReviewsCount(response?.data?.count));
        isFirstTime = false;
      } else {
        dispatch(reviewAndTipsAction.setEmployeeRevCount(response?.data?.count));
      }

      return response.data;
    } catch (e) {
      return e;
    }
  },
);
export const getTips = createAsyncThunk(
  `${name}/getTips`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IParams, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.GET_TIPS, { params });
      return response.data;
    } catch (e) {
      return e;
    }
  },
);


const initialState: IReviewsAndTips = {
  reviewAndTipsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  reviews: {},
  tips: {},
  reviewAndTipsError: null,
  detail: false,
  count: null,
  employeeRevCount: null,
};


const reviewAndTipsSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearData(state) {
      state.reviews = {};
      state.tips = {};
    },
    isDetailView(state) {
      state.detail = true;
    },
    isOverallView(state) {
      state.detail = false;
    },
    setReviewsCount(state, action) {
      state.count = action.payload;
    },
    setEmployeeRevCount(state, action) {
      state.employeeRevCount = action.payload;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getReviews, {
      status: 'reviewAndTipsStatus',
      data: 'reviews',
      error: 'reviewAndTipsError',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getTips, {
      status: 'reviewAndTipsStatus',
      data: 'tips',
      error: 'reviewAndTipsError',
      options: { concat: false, mergeResults: true },
    });
  },
});

export const { actions: reviewAndTipsAction } = reviewAndTipsSlice;
export const { reducer: reviewAndTipsReducer } = reviewAndTipsSlice;

export default reviewAndTipsSlice;
