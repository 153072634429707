import classes from './PhotosGallery.module.scss';
import SliderMobile from './SliderMobile';
import Gallery from '../Gallery/Gallery';
import { FC, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';


interface PhotosGalleryProps {
  images?: string[],
}

const PhotosGallery: FC<PhotosGalleryProps> = (props) => {
  const { images } = props;
  const [ defaultCurrentImage, setDefaultCurrentImage ] = useState<number | undefined>(undefined);
  const [ galleryOpen, setGalleryOpen ] = useState(false);
  const handleOpenGallery = (i: number) => {
    setGalleryOpen(true);
    setDefaultCurrentImage(i);
  };
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={classes.items}>
      {images?.map((item, i) => (
        <div onClick={() => handleOpenGallery(i)} className={classes.item} key={i}>
          <img src={item}/>
        </div>
      ))}
      <Gallery
        defaultCurrentImage={defaultCurrentImage}
        handleClose={() => {
          setDefaultCurrentImage(undefined);
          setGalleryOpen(false);
        }}
        images={images}
        open={galleryOpen}
        renderContent={
          isMobile
            ?
            <div className={classes.mobileGallery}>
              <SliderMobile
                setDefaultCurrentImage={setDefaultCurrentImage}
                images={images}
                setGalleryOpenMobile={setGalleryOpen}
              />
            </div>
            :
            undefined
        }
      />
    </div>
  );
};

export default PhotosGallery;
