import classes from './EmployeeCalendar.module.scss';
import { DatePicker } from 'antd';
// eslint-disable-next-line import/named
import { RangePickerProps } from 'antd/es/date-picker';
import { Dispatch, FC, SetStateAction } from 'react';

//FIX_ME поменять название и положить в shared/UI
const { RangePicker } = DatePicker;
export interface IDate {
  startDate: string,
  endDate: string
}
export const defaultDate: IDate = {
  startDate: '',
  endDate: '',
};
interface EmployeeCalendarProps {
  onChange: (
    value: RangePickerProps['value'],
    parsed: [string, string],
    setState: Dispatch<SetStateAction<IDate>>
  ) => void,
  setState: Dispatch<SetStateAction<IDate>>,
  valueCalendar: RangePickerProps['value'],
  dateClassName?: string,
  classNameContainer?: string
}

const EmployeeCalendar: FC<EmployeeCalendarProps> = (props) => {
  const { classNameContainer, dateClassName, onChange, setState, valueCalendar } = props;

  const hideKeyboard = () => {
    const inputElement = document.activeElement as HTMLElement;
    if (inputElement) {
      inputElement.blur();
    }
  };


  return (
    <div className={`${classes.container} ${classNameContainer}`}>
      <RangePicker
        placeholder={[ 'С', 'По' ]}
        bordered={false}
        showTime={{ format: 'DD.MM.YYYY' }}
        format="DD.MM.YYYY"
        className={`${dateClassName || ''} ${classes.date}`}
        popupClassName={classes.popupDate}
        onChange={(value, parsed) => {
          onChange(value, parsed, setState);
          hideKeyboard();
        }}
        value={valueCalendar}
        separator={
          <div className={classes.separator}></div>
        }
      />
    </div>
  );
};

export default EmployeeCalendar;
