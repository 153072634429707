import classes from './TabNavigateMobile.module.scss';
import EmployeeReviews from 'components/Dashboards/Employee/EmployeeReviews/EmployeeReviews';
import { FC, ReactNode, useState } from 'react';
import EmployeeTransactions from 'components/Dashboards/Employee/EmployeeTransactions/EmployeeTransactions';
import { TabsMobileUI } from 'shared/UI/TabsModileUI';
import { ITabOptionsMobile } from 'shared/UI/TabsModileUI/TabsMobileUI';
import { useAppSelector } from 'shared/hooks/redux';
import { StorageKey } from 'reduxApi/slices/adminPanelSlices/type/adminPanelTypes';


export enum ETabValues {
  TRANSACTION = 'TRANSACTION',
  REVIEWS = 'REVIEWS'
}

const TabValues: ITabOptionsMobile[] = [
  {
    id: ETabValues.REVIEWS,
    value: 'Отзывы',
  }, {
    id: ETabValues.TRANSACTION,
    value: 'Транзакции',
  },
];


const renderComponent: Record<ETabValues, ReactNode> = {
  [ ETabValues.REVIEWS ]: <EmployeeReviews/>,
  [ ETabValues.TRANSACTION ]: <EmployeeTransactions/>,
};

const TabNavigateMobile: FC = () => {
  const currentTab = useAppSelector(state => state.adminPanel[ StorageKey.CURRENTTAB ]);
  return (
    <div className={classes.container}>
      <TabsMobileUI
        currentTab={currentTab}
        variants={TabValues}
      />
      <div className={classes.component}>
        {renderComponent[ currentTab.id ]}
      </div>
    </div>
  );
};

export default TabNavigateMobile;
