import classes from './EmployeeProgressBar.module.scss';
import { FC } from 'react';
import { IDreams } from 'reduxApi/slices/employeeSlice/type/employeeSchema';


interface IPropsEmployeeProgressBar {
  dream?: IDreams,
}

const EmployeeProgressBar: FC<IPropsEmployeeProgressBar> = (props) => {
  const { dream } = props;

  return (
    <>
      <div className={classes.progress}>
        <div className={classes.progressContainer}>
          <div className={classes.progressCount}>
            {dream?.progress ?? '0'} <span className={classes.som}>c</span> из
            {` ${dream?.goal ?? '0'}`} <span className={classes.som}>c</span>
          </div>
          <div className={classes.progressCount}>
            Цель: <span className={classes.goal}>{dream?.goal ?? '0'} <span className={classes.som}>c</span></span>
          </div>
        </div>
        <div className={classes.progressBar}>
          <div className={classes.progressLine}>
            <div className={classes.progressBackground} style={{ width: dream?.percent + '%' }}></div>
          </div>
          <span className={classes.percent}>
            {dream?.percent ?? '0'}%
          </span>
        </div>
      </div>
    </>
  );
};

export default EmployeeProgressBar;
