'use client';
import classes from './Gallery.module.scss';
import Navigation from './Navigation/Navigation';
import ImageGallery from './ImageGallery/ImageGallery';
import { createPortal } from 'react-dom';
import { FC, ReactNode, memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';


interface GalleryProps {
  open: boolean,
  images?: string[],
  handleClose: () => void,
  defaultCurrentImage: number | undefined,
  renderContent?: ReactNode,
}

const Gallery: FC<GalleryProps> = (props) => {
  const { renderContent, open, images, handleClose, defaultCurrentImage } = props;
  const ref = useRef<Element | null>(null);
  const [ currentImage, setCurrentImage ] = useState(defaultCurrentImage);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
  }, []);

  useEffect(() => {
    setCurrentImage(defaultCurrentImage);

  }, [ defaultCurrentImage ]);

  useEffect(() => {
    if (open === false) {
      document.body.style.removeProperty('overflow');
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [ open ]);

  return (open && ref.current && currentImage !== undefined)
    ?
    createPortal(
      <div className={classNames(classes.wrapper, { [ classes.open ]: open })}>
        <div className={classes.overlay}>
          <img
            alt={''} src={images?.[ currentImage ]}
          />
        </div>
        <div className={classes.content}>
          {
            renderContent
              ?
              renderContent
              :
              <>
                <ImageGallery image={images?.[ currentImage ] || ''}/>
                <Navigation
                  handleClose={handleClose}
                  images={images}
                  currentImage={currentImage}
                  setCurrentImage={setCurrentImage}
                />
              </>
          }
        </div>

        <div
          className={classes.close}
          onClick={handleClose}
        >
          {/* eslint-disable-next-line max-len */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="IconSvg IconSvg_name_SvgClose IconSvg_size_24 ImageGalleryFullscreenVertical__closeButtonIcon"><path fill="#fff" d="M13.205 12 20 5.205 18.795 4 12 10.795 5.205 4 4 5.205 10.795 12 4 18.795 5.205 20 12 13.205 18.795 20 20 18.795 13.205 12z"></path></svg>
        </div>
      </div>,
      ref.current,
    )
    :
    null;
};

export default memo(Gallery);
