import classes from './TabsMobileUI.module.scss';
import { ETabValues } from 'components/Dashboards/Employee/EmployeeRevAndTran/mobile/TabNavigateMobile/TabNavigateMobile';

import { adminPanelActions } from 'reduxApi/slices/adminPanelSlices/model/adminPanel';
import { useAppDispatch } from 'shared/hooks/redux';


export interface ITabOptionsMobile {
  id: ETabValues,
  value: string,
}

interface ITabsProps {
  currentTab: ITabOptionsMobile,
  variants: ITabOptionsMobile[]
}

function TabsMobileUI({ currentTab, variants }: ITabsProps) {
  const dispatch = useAppDispatch();
  return (
    <div className={classes.tabs}>
      {variants.map(tab => <div
        key={tab.id}
        onClick={() => dispatch(adminPanelActions.setCurrentTab(tab))}
        className={`
          ${classes.button}
          ${currentTab.id === tab.id ?
      classes.selected
      : classes.notSelected
    }
          `}
      >
        {tab.value}
      </div>)}
    </div>
  );
}

export default TabsMobileUI;
