import classes from './Modal.module.scss';
import { FC, Dispatch, ReactNode } from 'react';
import { Modal } from 'antd';
import 'app/providers/override/modal.scss';
import CloseIcon from 'shared/assets/icons/CloseIcon';


interface ModalProps{
  children: ReactNode,
  open: boolean,
  setIsModalOpen: Dispatch<React.SetStateAction<any>>,
  setTemplates?: (state: string[]) => void,
  title?: string,
  className?: string,
  handleClose?: () => void,
}

const ModalWindow:FC<ModalProps> = (props) => {
  const { className, children, open, setIsModalOpen, title = '', setTemplates, handleClose } = props;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setTemplates && setTemplates([]);
    handleClose?.();
  };


  return (
    <Modal
      title={title}
      className={`${classes.modal} ${className ? className : ''}`}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={<CloseIcon />}
    >
      {children}
    </Modal>
  );
};

export default ModalWindow;
