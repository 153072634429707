import classes from './Thumb.module.scss';
import classNames from 'classnames';
import { DetailedHTMLProps, FC, HTMLAttributes, memo } from 'react';


interface ThumbProps {
  img: string,
  selected: boolean,
  onClick: (index: number) => void,
  index: number,

}

interface ThumbDivProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  name: string | number
}
export const sizeWidthPhotoThumb = 104;
const ThumbDiv: FC<ThumbDivProps> = memo((props) => <div {...props}></div>);
ThumbDiv.displayName = 'ThumbDiv';


const Thumb: FC<ThumbProps> = (props) => {
  const { img, selected, onClick, index } = props;

  return (
    <ThumbDiv
      name={index}
      onClick={() => onClick(index)}
      className={classNames(classes.image, { [ classes.selected ]: selected })}
    >
      <img width={sizeWidthPhotoThumb} height={78} src={img} alt="FIX_ME"/>
    </ThumbDiv>
  );
};

export default memo(Thumb);
