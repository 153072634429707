import { BASE_URL, LANGUAGE_LOCAL, REFRESH_COOKIE } from '../../shared/common/constants';
import { logOut, multiStorage } from 'shared/common/helpers';
import axios from 'axios';


export const requester = axios.create({ baseURL: BASE_URL });

requester.interceptors.request.use(config => {
  const token = multiStorage.getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers[ 'Accept-Language' ] = localStorage.getItem(LANGUAGE_LOCAL);
  return config;
});


requester.interceptors.response.use(config => {
  return config;
}, async(error) => {
  const originalRequest = error.config;
  if (
    error?.response?.data?.detail === 'Данный токен недействителен для любого типа токена'
    &&
    !error.config._isRetry
  ) {
    originalRequest._isRetry = true;
    try {
      const config = { refresh: multiStorage.getItem(REFRESH_COOKIE) };
      const response = await requester.post('/api/v1/refresh/', config);
      multiStorage.setItem('access', response.data?.access);

      return requester.request(originalRequest);
    }
    catch (e) {
      return null;
    }
  }

  if (
    error?.response?.data?.detail === 'Токен недействителен или просрочен'
    ||
    error?.response?.data?.refresh?.[ 0 ] === 'Это поле не может быть пустым.'
    ||
    localStorage.getItem(REFRESH_COOKIE) === 'null'
  ) {
    logOut();
  }
  throw error;
});
