import { IDataLogin } from '../type/authSchema';
import { ILogin } from '../../employeeListSlice/type/adminSchema';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases, multiStorage } from 'shared/common/helpers';
import { ACCESS_STORAGE, REFRESH_COOKIE, REQUEST_STATUSES } from 'shared/common/constants';


const name = 'auth';


const ENDPOINTS = { LOGIN: '/api/v1/login/' };


export const postLogin = createAsyncThunk(
  `${name}/postLogin`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data:IDataLogin, thunkApi: any) => {
    try {
      const response = await thunkApi.extra.post(ENDPOINTS.LOGIN, data);
      multiStorage.setItem(REFRESH_COOKIE, response.data.data.refreshToken);
      multiStorage.setItem(ACCESS_STORAGE, response.data.data.accessToken);
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e:any) {
      return thunkApi.rejectWithValue(e.response);
    }
  },
);


const initialState: ILogin = {
  authStatus: REQUEST_STATUSES.NOT_REQUESTED,
  auth: {},
  authError: null,
};


const authSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, postLogin, {
      status: 'authStatus',
      data: 'auth',
      error: 'authError',
    });
  },
});


export default authSlice;
