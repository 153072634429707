import { IMbankCheck, IMbankConfirm, IMbankCreate, IMbankRequests, IMbankStatus } from '../types/mbankTypes';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const name = 'mbank';

const ENDPOINTS = {
  CHECK: '/api/v1/payment/mbank/check/',
  CREATE: '/api/v1/payment/mbank/create/',
  CONFIRM: '/api/v1/payment/mbank/confirm/',
  STATUS: '/api/v1/payment/mbank/status/',
};

const initialState:IMbankRequests = {
  code: 0,
  comment: '',
  displayName: '',
  quid: '',
  loading: false,
};

export const getCheck = createAsyncThunk(
  `${name}/getCheck`,
  async (data:IMbankCheck, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.CHECK, data);
      return response.data;
    } catch (error: any) {
      return error;
    }
  },
);

export const getCreate = createAsyncThunk(
  `${name}/getCreate`,
  async (data:IMbankCreate, { extra: api, dispatch }: any) => {
    try {
      const response = await api.post(ENDPOINTS.CREATE, data);
      dispatch(MbankActions.setQuid(response?.data?.quid));
      return response.data;
    } catch (error: any) {
      return error;
    }
  },
);

export const getConfirm = createAsyncThunk(
  `${name}/getConfirm`,
  async (data:IMbankConfirm, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.CONFIRM, data);
      return response.data;
    } catch (error: any) {
      return error;
    }
  },
);

export const getStatus = createAsyncThunk(
  `${name}/getCreate`,
  async (data:IMbankStatus, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.CREATE, data);
      return response.data;
    } catch (error: any) {
      return error;
    }
  },
);


export const MbankSlice = createSlice({
  name,
  initialState,
  reducers: {
    setQuid(state, action) {
      state.quid = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCreate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCreate.fulfilled, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getCheck.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCheck.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCheck.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const { actions: MbankActions } = MbankSlice;
