import classes from '../Gallery.module.scss';
import { FC, memo } from 'react';


interface ImageProps {
  image: string,
}

const ImageGallery: FC<ImageProps> = (props) => {
  const { image } = props;
  return (
    <div className={classes.image}>
      <img
        alt={''}
        className={classes.currentImage}
        src={image}
      />
    </div>
  );
};

export default memo(ImageGallery);
