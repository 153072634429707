import {
  IDataRequest,
  IDataReq,
  ISchemaEmployee,
  IParamReview,
  IPostChangePasswordData,
  EStatuses,
  IDreamReq,
  IPostEmailAddress,
  IConfirmEmailAddress,
  IAdminRequest,
  IResetPassword,
  IVerifyPassword,
  IResetPasswordCreate,
} from '../type/employeeSchema';
// eslint-disable-next-line import/named
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addQueryCases, logOut } from 'shared/common/helpers';
// import { REQUEST_STATUSES } from 'shared/common/constants';
import type { AxiosResponse } from 'axios';


export enum REQUEST_STATUSES {
  NOT_REQUESTED = 'notRequested',
  REQUESTED = 'requested',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}
const name = 'employee';


const ENDPOINTS = {
  PROFILE: '/api/v1/employer/profile/',
  TIPS: '/api/v1/employer/profile/tips/',
  REVIEWS: '/api/v1/employer/profile/reviews/',
  REQUEST_MONEY: '/api/v1/employer/profile/request-money/',
  CHANGE_PASSWORD: '/api/v1/employer/passwords/change/',
  DREAMS: '/api/v1/employer/profile/dreams/',
  EMAIL: '/api/v1/email/change/',
  CONFIRM_EMAIL: '/api/v1/email/confirm/',
  CHANGE_ADMIN_PASSWORD: '/api/v1/password/change/',
  ADMIN_PROFILE: '/api/v1/place-admin/profile/',
  RESET_PASSWORD: '/api/v1/password/reset/send-link/',
  RESET_PASSWORD_CREATE: '/api/v1/password/reset/verify/',
  VERIFY_PASSWORD: '/api/v1/password/reset/verify/',
};

const initialState: ISchemaEmployee = {
  employeeStatus: REQUEST_STATUSES.NOT_REQUESTED || '',
  employee: {},
  employeeError: null,
  tips: {},
  tipsStatus: REQUEST_STATUSES.NOT_REQUESTED || '',
  requestMoney: {},
  requestMoneyStatus: REQUEST_STATUSES.NOT_REQUESTED || '',
  reviews: {},
  reviewsStatus: REQUEST_STATUSES.NOT_REQUESTED || '',
  canceled: {},
  canceledStatus: REQUEST_STATUSES.NOT_REQUESTED || '',
  pending: {},
  pendingStatus: REQUEST_STATUSES.NOT_REQUESTED || '',
  dreams: [],
  dreamsStatus: REQUEST_STATUSES.NOT_REQUESTED || '',
  emailOpened: false,
};

export const getProfile = createAsyncThunk(
  `${name}/getProfile`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.PROFILE);
      return response?.data?.user;
    } catch (error: any) {
      logOut();
      if (error.response?.data?.detail === 'У вас недостаточно прав для выполнения данного действия.') {
        logOut();
      }
      return Promise.reject(initialState.employee);
    }
  },
);

export const patchProfile = createAsyncThunk(
  `${name}/patchProfile`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IDataRequest | FormData, { extra: api }: any) => {
    try {
      const response = await api.patch(ENDPOINTS.PROFILE, data);
      return response?.data?.user;
    } catch (error) {
      return Promise.reject(initialState.employee);
    }
  },
);

export const patchAdminProfile = createAsyncThunk(
  `${name}/patchAdminProfile`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IAdminRequest | FormData, { extra: api }: any) => {
    try {
      const response = await api.patch(ENDPOINTS.ADMIN_PROFILE, data);
      return response?.data?.user;
    } catch (error) {
      return Promise.reject(initialState.employee);
    }
  },
);

export const getDreams = createAsyncThunk(
  `${name}/getDreams`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.DREAMS);
      return response?.data;
    } catch (error) {
      return Promise.reject(initialState.employee);
    }
  },
);

export const patchDream = createAsyncThunk(
  `${name}/patchDream`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IDreamReq, { extra: api }: any) => {
    try {
      const response = await api.patch(`${ENDPOINTS.DREAMS}${params.id}/`, { ...params });
      return response?.data;
    } catch (error) {
      return Promise.reject(initialState.employee);
    }
  },
);

export const getTipsEmployee = createAsyncThunk(
  `${name}/getTipsEmployee`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IDataReq, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.TIPS, { params });
      return response.data;
    } catch (error) {
      return {};
    }
  },
);

export const getRequestMoneyEmployee = createAsyncThunk(
  `${name}/getRequestMoneyEmployee`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IDataReq, { extra: api }: any) => {
    try {
      const response = await api.get(`${ENDPOINTS.REQUEST_MONEY}?status=${EStatuses.approved}`, { params });
      return response.data;
    } catch (error) {
      return {};
    }
  },
);

export const getRequestMoneyEmployeePending = createAsyncThunk(
  `${name}/getRequestMoneyEmployeePending`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IDataReq, { extra: api }: any) => {
    try {
      const response = await api.get(`${ENDPOINTS.REQUEST_MONEY}?status=${EStatuses.pending}`, { params });
      return response.data;
    } catch (error) {
      return {};
    }
  },
);

export const getRequestMoneyEmployeeCanceled = createAsyncThunk(
  `${name}/getRequestMoneyEmployeeCanceled`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IDataReq, { extra: api }: any) => {
    try {
      const response = await api.get(`${ENDPOINTS.REQUEST_MONEY}?status=${EStatuses.canceled}`, { params });
      return response.data;
    } catch (error) {
      return {};
    }
  },
);

export const getReviewsEmployee = createAsyncThunk(
  `${name}/getReviewsEmployee`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IParamReview, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.REVIEWS, { params });
      return response.data;
    } catch (error) {
      return {};
    }
  },
);

export const postChangePassword = createAsyncThunk(
  `${name}/postChangePassword`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IPostChangePasswordData, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.CHANGE_PASSWORD, data);
      return response.data.user;
    } catch (error) {
      return {};
    }
  },
);

export const postChangeAdminPassword = createAsyncThunk(
  `${name}/postChangePassword`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IPostChangePasswordData, { extra: api }: any) => {
    try {
      const response: AxiosResponse<object> = await api.post(ENDPOINTS.CHANGE_ADMIN_PASSWORD, data);
      return response.data;
    } catch (e: any) {
      return e.response;
    }
  },
);

export const postEmailAddress = createAsyncThunk(
  `${name}/postEmailAddress`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IPostEmailAddress, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.EMAIL, data);
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const confirmEmailAddress = createAsyncThunk(
  `${name}/confirmEmailAddress`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IConfirmEmailAddress, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.CONFIRM_EMAIL, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const resetPassword = createAsyncThunk(
  `${name}/resetPassword`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IResetPassword, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.RESET_PASSWORD, data);
      return response.data;
    } catch (error) {
      return (error as any).response.data;
    }
  },
);

export const resetPasswordCreate = createAsyncThunk(
  `${name}/resetPasswordCreate`,
  async (data: IResetPasswordCreate, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.RESET_PASSWORD_CREATE, data);
      return response.data;
    } catch (error) {
      return (error as any).response.data;
    }
  },
);

export const verifyPassword = createAsyncThunk(
  `${name}/resetPassword`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IVerifyPassword, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.VERIFY_PASSWORD, data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

const employeeSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearData(state, action: PayloadAction<keyof ISchemaEmployee>) {
      state[ action.payload ] = initialState[ action.payload ];
    },
    updateDreams(state, action) {
      state.employee.dream = action.payload;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getProfile, {
      status: 'employeeStatus',
      data: 'employee',
      error: 'employeeError',
      options: { concat: false },
    });

    addQueryCases(builder, patchProfile, {
      status: 'employeeStatus',
      data: 'employee',
      error: 'employeeError',
      options: { concat: false },
    });
    addQueryCases(builder, postChangePassword, {
      status: 'employeeStatus',
      data: 'employee',
      error: 'employeeError',
      options: { concat: false },
    });
    addQueryCases(builder, getTipsEmployee, {
      status: 'tipsStatus',
      data: 'tips',
      error: '',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getReviewsEmployee, {
      status: 'reviewsStatus',
      data: 'reviews',
      error: '',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getRequestMoneyEmployee, {
      status: 'requestMoneyStatus',
      data: 'requestMoney',
      error: '',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getRequestMoneyEmployeePending, {
      status: 'pendingStatus',
      data: 'pending',
      error: '',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getRequestMoneyEmployeeCanceled, {
      status: 'canceledStatus',
      data: 'canceled',
      error: '',
      options: { concat: false, mergeResults: true },
    });
    addQueryCases(builder, getDreams, {
      status: 'dreamsStatus',
      data: 'dreams',
      error: '',
      options: { concat: false, mergeResults: false },
    });
  },
});
export const { actions: employeeActions } = employeeSlice;
export const { reducer: employeeReducer } = employeeSlice;
export default employeeSlice;
