import { REQUEST_STATUSES } from 'shared/common/constants';


export interface IRequestEmployee {
  id?: string,
  is_blocked?: boolean,
  login?: string,
  created_at?: string,
  first_notify_date?: string,
  type?: string,
  email?: string | null,
  full_name?: string,
  photo?: string,
  code?: string,
  balance?: number,
  dream?: IDreams,
  phone?: string | null
  is_first_time?: boolean,
  filial?: {
    id?: string,
    place_name?: string,
    place_type?: string,
    title?: string,
    place?: string,
    code?: string,
    employers_amount?: number,
    rating?: number,
    [key: string]: any
  },
  rating?: number,
  [key: string]: any
}

interface ITipReview {
  date?: string,
  hour?: string,
  amount?: string,
  comment?: string,
  rating?: number,
  review_templates: string[]
  review_images?: string[]
}

export enum EStatuses {
  approved = 'approved',
  pending = 'pending',
  canceled = 'canceled'
}

export interface ITipsReviewsRequestMoney {
  next?: string | null,
  count?: number,
  previous?: string | null,
  results?: ITipReview[],
}

export interface ISchemaEmployee {
  employeeStatus: REQUEST_STATUSES,
  tipsStatus: REQUEST_STATUSES,
  requestMoneyStatus: REQUEST_STATUSES,
  reviewsStatus: REQUEST_STATUSES,
  employee: IRequestEmployee,
  employeeError: any,
  tips: ITipsReviewsRequestMoney,
  reviews: ITipsReviewsRequestMoney,
  requestMoney: ITipsReviewsRequestMoney,
  canceled: ITipsReviewsRequestMoney,
  canceledStatus: REQUEST_STATUSES,
  pending: ITipsReviewsRequestMoney,
  pendingStatus: REQUEST_STATUSES,
  dreams: IDreams[],
  dreamsStatus: REQUEST_STATUSES,
  emailOpened: boolean
}

export interface IDreams {
  id: string,
  title?: string,
  goal?: string,
  progress?: number,
  date_of_finish?: string,
  created_at?: string,
  is_finish: boolean,
  percent?: number,
}

export interface IDreamReq {
  id?: string,
  title?: string,
  goal?: string | number
}

export interface IDataRequest {
  full_name?: string,
  email?: string,
  photo?: any,
  dream?: string,
  phone?: string
}

export interface IAdminRequest {
  full_name?: string,
  photo?: any,
}

export interface IDataReq {
  limit?: number | string,
  offset?: number | string,
  startDate?: string,
  endDate?: string,
  status?: EStatuses
}
export interface IParamReview extends IDataReq {
  reviewType?: number | string
}
export type TPayloadClear = 'tips' | 'requestMoney' | 'reviews'

export interface IPostChangePasswordData {
  old_password: string,
  new_password?: string,
  confirm_new_password?: string
  [key: string]: string | undefined
}

export interface IPostEmailAddress {
  email: string,
  password: string
}

export interface IConfirmEmailAddress {
  code: string
}

export interface IResetPassword {
  email: string
}

export interface IResetPasswordCreate {
  code: string,
  new_password: string,
  confirm_new_password: string
}

export interface IVerifyPassword {
  code: string,
  new_password: string,
  confirm_new_password: string
}

export interface IDreamFormState {
  valueDream: string,
  valueGoal: string,
  mBankNumber: string,
}
