import { IPlaceData } from '../type/placesSchema';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';


const name = 'places';


const ENDPOINTS = { PLACES_REQUEST: '/api/v1/place/request_to_connection/' };


export const postPlace = createAsyncThunk(
  'name/postPlace',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IPlaceData, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.PLACES_REQUEST, data);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);


const initialState = {
  placesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  places: [],
  placesError: null,
};


const placesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, postPlace, {
      status: 'placesStatus',
      data: 'places',
      error: 'placesError',
    });
  },
});


export default placesSlice;
