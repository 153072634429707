import { ISchemaNotification } from '../type/notificationScheme';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { REQUEST_STATUSES } from 'shared/common/constants';
import { addQueryCases } from 'shared/common/helpers';


const name = 'notification';


const ENDPOINTS = {
  NOTIFICATION: '/api/v1/notification',
  NOTIFICATION_READ: '/api/v1/notification/read/',
  NOTIFICATION_READ_ALL: '/api/v1/notification/read-all',
};

const initialState: ISchemaNotification = {
  notificationStatus: REQUEST_STATUSES.NOT_REQUESTED,
  notification: [],
  notificationError: null,
};

export const getNotification = createAsyncThunk(
  `${name}/getNotification`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api, dispatch }: any) => {
    try {
      const response = await api.get(ENDPOINTS.NOTIFICATION);

      return dispatch(notificationActions.updateNotifications(response?.data?.notificationsData));
    } catch (error) {
      return Promise.reject(initialState.notification);
    }
  },
);

export const postNotifications = createAsyncThunk(
  `${name}/patchNotification`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.NOTIFICATION_READ_ALL);
      return response?.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.response;
    }
  },
);

const notificationSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateNotifications (state, action) {
      state.notification = action.payload;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getNotification, {
      status: 'notificationStatus',
      data: 'notification',
      error: 'notificationError',
      options: { concat: true, mergeResults: false },
    });
  },
});

export const { actions: notificationActions } = notificationSlice;
export const { reducer: notificationReducer } = notificationSlice;
export default notificationSlice;
