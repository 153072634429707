import { ETabValues } from 'components/Dashboards/Employee/EmployeeRevAndTran/mobile/TabNavigateMobile/TabNavigateMobile';
import { ITabOptionsMobile } from 'shared/UI/TabsModileUI/TabsMobileUI';


export const BASE_URL = process.env.REACT_APP_BASE_URL;

export enum REQUEST_STATUSES {
  NOT_REQUESTED = 'notRequested',
  REQUESTED = 'requested',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum userTypes {
  admin = 'place_admin',
  employer = 'employer',
}

export enum EditEmployeeTabs {
  AUTH = 'AUTH',
  EDIT_PROFILE = 'EDIT_PROFILE',
  CHECK_OLD_PASSWORD = 'CHECK_OLD_PASSWORD',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
  SET_NEW_EMAIL = 'SET_NEW_EMAIL',
  CHECK_NEW_EMAIL = 'CHECK_NEW_EMAIL',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  PASSWORD_SENT = 'PASSWORD_SENT',
  EMAIL_VERIFY_PAGE = 'EMAIL_VERIFY_PAGE',
  PASSWORD_VERIFY_PAGE = 'PASSWORD_VERIFY_PAGE',
}

export const currentURL = 'https://tipson.kg';
export const LANGUAGE_LOCAL = 'currentLang';

export const PATH = {
  main: '/',
  tipsCheck: '/tips',
  tipsThanks: '/tips-thanks-page',
  reviewThanks: '/review-thanks-page',
  employeeSearch: '/employee-search',
  admin: '/admin-page',
  employeeProfile: '/profile/',
  editEmployeeProfile: '/edit/',
  editAdminProfile: '/edit-admin',
  auth: '/auth',
  instructions: '/instructions',
  qr: '/admin-qr',
  selectPayment: '/select-payment',
  mbank: '/mbank',
  blockedPage: '/blocked',
};

export const USER_NAME = 'user';
export const BOT_NAME = 'tipsonGSbot';
export const USER_TYPE = 'type';
export const adminType = 'place_admin';
export const REFRESH_COOKIE = 'refresh';
export const ACCESS_STORAGE = 'access';
export type TColorEmployeeCard = 'green' | 'gray'
export enum ETypesCardEmployee {
  rev = 'reviews',
  tips = 'tips'
}
export enum EEmojiTypes {
  'angry' = 1,
  'bad' = 2,
  'mid' = 3,
  'good' = 4,
  'best' = 5,
}

export const setSeparatorNum = (num: number | undefined | string) => {
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const regExpEmail = /^([0-9A-Za-z]+[-0-9A-Za-z\.]+[0-9A-Za-z])@([-A-Za-z]+\.{1,2}[-A-Za-z]{2,})$/u;


export const TabValues: ITabOptionsMobile[] = [
  {
    id: ETabValues.REVIEWS,
    value: 'Отзывы',
  }, {
    id: ETabValues.TRANSACTION,
    value: 'Чаевые',
  },
];
