import classes from './AnimationContainer.module.scss';
import { ReactElement, FC, Children } from 'react';


enum typesAnimation {
  OPACITY = 'opacity',
}
interface AnimationContainerProps {
  children: ReactElement;
  typeAnimation?: typesAnimation
}

const AnimationContainer: FC<AnimationContainerProps> = (props) => {
  const { children, typeAnimation } = props;
  const classProps = classes[ typeAnimation ? typeAnimation : typesAnimation.OPACITY + 'Children' ];
  return (
    <>
      {
        Children.map(children, child =>
          <div className={classProps}>
            {child}
          </div>,
        )
      }
    </>
  );
};

export default AnimationContainer;
