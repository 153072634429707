import { PaymentShema } from '../type/paymentShema';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';


const name = 'payment';

const ENDPOINTS = { PAYMENT: '/api/v1/payment/init_payment/' };

interface IPaymentArguments{
  employer: string
  pg_amount: number
  success_url: string
  failure_url: string
  commission: number
}

interface ExtendedPaymentShema extends PaymentShema {
  paymentData: {
    employer: string,
    pg_amount: number,
    success_url: string,
    failure_url: string,
    commission: number,
  }
}

export const postPayment = createAsyncThunk(
  `${name}/postPayment`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: IPaymentArguments, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.PAYMENT, data);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

const initialState: ExtendedPaymentShema = {
  paymentStatus: REQUEST_STATUSES.NOT_REQUESTED,
  payment: [],
  paymentError: null,
  paymentData: {
    employer: '',
    pg_amount: 0,
    success_url: '',
    failure_url: '',
    commission: 0,
  },
};

const paymentSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, postPayment, {
      status: 'paymentStatus',
      data: 'payment',
      error: 'paymentError',
    });
  },
});

export const { setPaymentData } = paymentSlice.actions;

export default paymentSlice;
