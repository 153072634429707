import EmployeeInfiniteScroll from '../EmployeeInfiniteScroll/EmployeeInfiniteScroll';
import classesTransact from '../EmployeeTransactions/EmployeeTransactions.module.scss';
import { ETypesCardEmployee, REQUEST_STATUSES } from 'shared/common/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { TabsFilter } from 'shared/UI/TabsFilter';
import {
  employeeActions,
  getReviewsEmployee,
} from 'reduxApi/slices/employeeSlice/model/employeeSlice';
import { ITabOptions } from 'components/Dashboards/Admin/ReviewTipsOnBlock/ReviewTipsOnBlock';
import EmployeeCalendar, { IDate } from 'components/EmployeeCalendar/EmployeeCalendar';
// eslint-disable-next-line import/named
import { RangePickerProps } from 'antd/es/date-picker';
import notFoundImg from 'shared/assets/3227426.png';


export enum ReviewTabsVariants {
  ALL = 'all',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

const options: ITabOptions<ReviewTabsVariants>[] = [
  { id: 1, title: 'Все', value: ReviewTabsVariants.ALL },
  { id: 2, title: 'Положительные', value: ReviewTabsVariants.POSITIVE },
  { id: 3, title: 'Отрицательные', value: ReviewTabsVariants.NEGATIVE },
];

const EmployeeReviews: FC = () => {
  const dispatch = useAppDispatch();
  const [ params, setParams ] = useState({});
  const reviews = useAppSelector(state => state.employee.reviews);
  const reviewsStatus = useAppSelector(state => state.employee.reviewsStatus);

  const [ valueCalendar, setValueCalendar ] = useState<RangePickerProps['value'] | undefined>();

  const [ currentSelect, setCurrentSelect ] = useState<string | undefined>('all');

  const handleSelect = (select: string) => {
    const current = options.find((opt) => opt.value === select);
    setCurrentSelect(current?.value);
    dispatch(employeeActions.clearData('reviews'));
    setParams({ ...params, reviewType: current?.value });
  };

  const handleChangeDate = (value: RangePickerProps['value'],
    parsed: [string, string],
    setParams: Dispatch<SetStateAction<IDate>>,
  ) => {
    dispatch(employeeActions.clearData('reviews'));
    setValueCalendar(value);
    setParams({
      ...params,
      startDate: parsed[ 0 ],
      endDate: parsed[ 1 ],
    });
  };

  return (
    <>
      <div className={classesTransact.tabs}>
        <EmployeeCalendar
          valueCalendar={valueCalendar}
          setState={setParams}
          onChange={handleChangeDate}
        />
        <div className={classesTransact.buttons}>
          <TabsFilter<ReviewTabsVariants>
            currentOption={options.find(option => option.value === currentSelect) ?? options[ 0 ]}
            options={options}
            handleSelect={handleSelect}
          />
        </div>
      </div>
      {
        reviewsStatus === REQUEST_STATUSES.SUCCEEDED
        &&
        reviews.results?.length === 0
          ?
          <div className={classesTransact.notFoundContainer}>
            <img className={classesTransact.notFoundImg} src={notFoundImg} alt="" />
            <p className={classesTransact.textNotFound}>Отзывов пока нет</p>
          </div>
          :
          <EmployeeInfiniteScroll
            dataStatus={reviewsStatus}
            type={ETypesCardEmployee.rev}
            getDispatchData={getReviewsEmployee}
            dataSelector={reviews}
            specParams={params}
            hardDep={params}
            clearData={'reviews'}
          />
      }

    </>
  );
};
export default EmployeeReviews;
