import classes from './ChangeLanguage.module.scss';
import changeIcon from 'shared/assets/icons/change_lang.svg';
import changeIconBlack from 'shared/assets/icons/changeLangBlack.svg';
import { FC, useEffect, useState } from 'react';
import i18next from 'i18next';
import { LANGUAGE_LOCAL } from 'shared/common/constants';
import { Select } from 'antd';


export enum ELanguages {
  ru = 'ru',
  en = 'en',
  ky = 'ky'
}
const ru = { value: 'ru', label: 'Русский' };
const en = { value: 'en', label: 'English' };
const ky = { value: 'ky', label: 'Кыргызча' };
const options = { ru, ky, en };
interface Props {
  color: 'black' | 'white'
}
const ChangeLanguage: FC<Props> = (props) => {
  const { color } = props;

  const [ currentLang, setCurrentLang ] = useState(ru);

  useEffect(() => {
    const lang = localStorage.getItem(LANGUAGE_LOCAL) || ELanguages.ru;
    setCurrentLang(options[ lang as ELanguages ]);
    i18next.changeLanguage(lang);
  }, []);

  const changeLanguage = (lang: ELanguages) => {
    setCurrentLang(options[ lang ]);
    localStorage.setItem(LANGUAGE_LOCAL, lang);
    i18next.changeLanguage(lang);
    window.location.reload();
  };

  return (
    <Select
      className={`${classes.select} ${classes[ color ]}`}
      value={currentLang.label as ELanguages}
      options={Object.values(options).filter(item => {
        if (item.value !== currentLang.value) {
          return item;
        }
      })}
      popupClassName={classes.popup}
      onChange={changeLanguage}
    />
  );
};

export default ChangeLanguage;
