import { FC, useRef, useState } from 'react';


interface IDraggableOverflow {
  children: React.ReactNode;
  classname?: string
}

const DraggableOverflow: FC<IDraggableOverflow> = ({ children, classname }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [ isDown, setIsDown ] = useState(false);
  const [ startX, setStartX ] = useState<number | null>(null);
  const [ scrollLeft, setScrollLeft ] = useState<number | null>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDown(true);
    if (containerRef.current) {
      containerRef.current.classList.add('active');
      setStartX(e.pageX - containerRef.current.offsetLeft);
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    if (containerRef.current) {
      containerRef.current.classList.remove('active');
    }
  };

  const handleMouseUp = () => {
    setIsDown(false);
    if (containerRef.current) {
      containerRef.current.classList.remove('active');
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDown) return;
    e.preventDefault();
    if (containerRef.current) {
      const x = e.pageX - containerRef.current.offsetLeft;
      const walk = (x - startX!) * 1;
      containerRef.current.scrollLeft = scrollLeft! - walk;
    }
  };

  return (
    <div
      className={classname}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {children}
    </div>
  );
};
export default DraggableOverflow;
