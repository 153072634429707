import cls from './LoaderComponent.module.scss';
import { FC } from 'react';
import img from 'shared/assets/icons/loader.svg';


const LoaderComponent:FC = () => {


  return (
    <div
      className={cls.container}
    >
      <dotlottie-player src="https://lottie.host/28ca761d-7ee1-4399-891c-f16529ece981/rwTNlU0M3F.json"
        background="transparent" speed="1" style={{ width: 150, height: 150, display: 'block' }} loop autoplay></dotlottie-player>
    </div>
  );
};

export default LoaderComponent;
