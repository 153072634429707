import classes from './EmployeeFirstCreateCard.module.scss';
import { ETypesOfCard } from './EmployeeCards';
import { ETabValues } from '../EmployeeModal';
import EmployeeAccountsCards from '../components/EmployeeAccountsCards/EmployeeAccountsCards';
import AnimationContainer from 'shared/UI/AnimationContainer/AnimationContainer';
import { Dispatch, FC, SetStateAction } from 'react';
import CustomButton from 'shared/UI/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { REQUEST_STATUSES, setSeparatorNum } from 'shared/common/constants';
import CustomInput from 'shared/UI/CustomInput/CustomInput';
import { requestMoneyFormActions } from 'reduxApi/slices/requestMoneyFormSlice/model/requestMoneyFormSlice';
import { IDataParamsRequestMoney, IFormRequestMoney } from 'reduxApi/slices/bankAccountSlice/type/bankAccountType';
import { toast } from 'react-toastify';
import { postRequestMoney } from 'reduxApi/slices/bankAccountSlice/model/bankAccountSlice';


interface EmployeeSelectCardProps {
  setTab: Dispatch<SetStateAction<ETabValues>>,
  setModal: Dispatch<SetStateAction<boolean>>,
}

const MIN_SUM = 30;

const EmployeeSelectCard: FC<EmployeeSelectCardProps> = (props) => {
  const { setTab, setModal } = props;

  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.employee.employee);
  const form = useAppSelector(state => state.requestMoneyForm);
  const balance = setSeparatorNum(user?.balance);
  const bankAccountStatus = useAppSelector(state => state.bankAccount.bankAccountStatus);

  const isLoading = bankAccountStatus === REQUEST_STATUSES.REQUESTED;
  const amount = form.amount;
  const handleChange = (value: string) => {
    dispatch(requestMoneyFormActions.handleChangeAmount({
      value: value,
      key: 'amount',
      balance: user?.balance,
    }));
  };
  const handleSelectFullAmount = () => {
    dispatch(requestMoneyFormActions.handleSelectFullAmount(balance || form.amount.value));
  };

  const handleSubmit = async () => {
    if (form.isCardSelected) {
      const backForm: IDataParamsRequestMoney = { amount: amount.value.replace(/\s/g, '') };

      Object.keys(form.cardData).forEach((item: keyof IFormRequestMoney) => {
        backForm[ item ] = form.cardData[ item ].value;
      });

      if (+amount.value < MIN_SUM) {
        toast.error('Минимальная сумма вывода 30 сом');
        return;
      }

      if (amount.value) {
        await dispatch(postRequestMoney({
          ...backForm,
          type: form.cardData.type.value === ETypesOfCard.BANK_CARD
            ?
            ETypesOfCard.BANK_CARD
            :
            ETypesOfCard.MBANK,
        }))
          .then(({ payload }) => {
            if (payload.message === 'CREATED') {
              toast.success('Ваш запрос успешно отправлен');
              setModal(false);
            } else {
              toast.error('Произошла ошибка');
            }
          });
      } else {
        toast.error('Впишите сумму вывода');
      }
    } else {
      toast.error('Выберите карту');
    }
  };
  return (
    <AnimationContainer>
      <div>
        <div className={classes.text}>Вывод средств</div>
        <div className={classes.content}>
          <CustomButton
            classname={classes.currentCheckButton}
            disabled
            color={'gradient_purple'}
            variant={'contained'}
          >
            <div className={classes.currentCheck}>
            Текущий счет:
            </div>
            <div className={classes.check}>
              {balance} сом
            </div>
          </CustomButton>
          <CustomInput
            error={amount.error}
            value={amount.value}
            onChange={(e) => handleChange(e.target.value)}
            placeholder={amount.placeholder}
            afterSuffix={
              <div
                className={classes.afterSuffix}
                onClick={handleSelectFullAmount}
              >
                  Вывести все
              </div>
            }
          />
          <EmployeeAccountsCards setTab={setTab}/>
          <p className={classes.note}>Минимальная сумма вывода - 30 сом. Вывод чаевых производится в рабочие дни с 9:00 до 16:30.</p>
          <div className={classes.actionButtons}>
            <CustomButton
              classname={classes.cancel}
              color={'black'}
              variant={'outlined'}
              onClick={() => setModal(false)}
            >
            Отмена
            </CustomButton>
            <CustomButton
              color={'black'}
              variant={'contained'}
              onClick={handleSubmit}
              disabled={isLoading}
            >
            Запросить
            </CustomButton>
          </div>
        </div>
      </div>
    </AnimationContainer>
  );
};

export default EmployeeSelectCard;
