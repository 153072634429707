import classes from './PhotosGallery.module.scss';
import { Dispatch, FC, SetStateAction } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


interface SliderMobileProps {
  images?: string[],
  setGalleryOpenMobile: Dispatch<SetStateAction<boolean>>,
  setDefaultCurrentImage: Dispatch<SetStateAction<number | undefined>>
}

const SliderMobile: FC<SliderMobileProps> = (props) => {
  const { images, setGalleryOpenMobile, setDefaultCurrentImage } = props;
  const handleOpenGallery = (page: number) => {
    setGalleryOpenMobile(true);

    setDefaultCurrentImage(page);
  };
  return (
    <Swiper
      height={191}
      slidesPerView={'auto'}
      spaceBetween={0}
      className={classes.sliderMain}
      onClick={() => handleOpenGallery(0)}
      onSlideChange={(page) => setDefaultCurrentImage(page.activeIndex)}
    >
      {
        images?.map((item, i) => (
          <SwiperSlide className={classes.slideMain} key={item}>
            <img
              width={254}
              height={191}
              onClick={() => handleOpenGallery(i + 1)}
              src={item}
              alt="Main Image Product FIX_ME"
              loading={'lazy'}
            />
          </SwiperSlide>
        ))
      }
    </Swiper>
  );
};

export default SliderMobile;
