import classes from './EmployeeTransactions.module.scss';
import EmployeeInfiniteScroll from '../EmployeeInfiniteScroll/EmployeeInfiniteScroll';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { TabsFilter } from 'shared/UI/TabsFilter';
import {
  employeeActions,
  getRequestMoneyEmployee,
  getRequestMoneyEmployeeCanceled,
  getRequestMoneyEmployeePending,
  getTipsEmployee,
} from 'reduxApi/slices/employeeSlice/model/employeeSlice';
// eslint-disable-next-line import/named
import { RangePickerProps } from 'antd/es/date-picker';
import EmployeeCalendar, { IDate, defaultDate } from 'components/EmployeeCalendar/EmployeeCalendar';
import { REQUEST_STATUSES } from 'shared/common/constants';
import notFoundImg from 'shared/assets/6155814.png';


enum EOptions {
  in = 'tips',
  out = 'requestMoney',
  canceled = 'canceled',
  pending = 'pending'
}

const options = [
  { id: 2, title: 'Поступившие', value: EOptions.in },
  { id: 1, title: 'Выведенные', value: EOptions.out },
  { id: 3, title: 'В ожидании', value: EOptions.pending },
  { id: 4, title: 'Отмененные', value: EOptions.canceled },
];


const EmployeeTransactions: FC = () => {
  const dispatch = useAppDispatch();
  const {
    tips,
    tipsStatus,
    requestMoney,
    requestMoneyStatus,
    canceled,
    canceledStatus,
    pending,
    pendingStatus,
  } = useAppSelector(state => state.employee);
  const [ currentSelect, setCurrentSelect ] = useState<EOptions>(EOptions.in);
  const [ paramsTips, setParamsTips ] = useState<IDate>(defaultDate);
  const [ paramsReqMoney, setParamsReqMoney ] = useState<IDate>(defaultDate);
  const [ paramsPending, setParamsPending ] = useState<IDate>(defaultDate);
  const [ paramsCanceled, setParamsCanceled ] = useState<IDate>(defaultDate);
  const [ valueCalendar, setValueCalendar ] = useState<RangePickerProps['value'] | undefined>();

  const handleSelect = (select: string) => {
    const current = options.find((opt) => opt.value === select);
    setCurrentSelect(current?.value || EOptions.in);
    setValueCalendar(undefined);
  };

  const handleChangeDate = (value: RangePickerProps['value'],
    parsed: [string, string],
    setParams: Dispatch<SetStateAction<IDate>>,
  ) => {
    dispatch(employeeActions.clearData(currentSelect));
    setValueCalendar(value);
    setParams({
      startDate: parsed[ 0 ],
      endDate: parsed[ 1 ],
    });
  };

  const setStateChanger = () => {
    if (currentSelect === EOptions.in) {
      return setParamsTips;
    } else if (currentSelect === EOptions.out) {
      return setParamsReqMoney;
    } else if (currentSelect === EOptions.pending) {
      return setParamsPending;
    } else if (currentSelect === EOptions.canceled) {
      return setParamsCanceled;
    } else {
      return setParamsTips;
    }
  };

  return (
    <>
      <div className={classes.tabs}>
        <EmployeeCalendar
          valueCalendar={valueCalendar}
          setState={setStateChanger()}
          onChange={handleChangeDate}
        />
        <div className={classes.buttons}>
          <TabsFilter<EOptions>
            currentOption={options.find(option => option.value === currentSelect) ?? options[ 0 ]}
            options={options}
            handleSelect={handleSelect}
          />
        </div>
      </div>
      <div
        className={currentSelect === EOptions.in ? classes.visible : classes.hidden}
      >
        {
          tipsStatus === REQUEST_STATUSES.SUCCEEDED
          &&
          tips.results?.length === 0
            ?
            <div className={classes.notFoundContainer}>
              <img className={classes.notFoundImg} src={notFoundImg} alt="" />
              <p className={classes.textNotFound}>Чаевых пока нет</p>
            </div>
            :
            <EmployeeInfiniteScroll
              dataStatus={tipsStatus}
              color={'green'}
              getDispatchData={getTipsEmployee}
              dataSelector={tips}
              specParams={paramsTips}
              hardDep={paramsTips}
              clearData={'tips'}
            />
        }
      </div>
      <div
        className={currentSelect === EOptions.out ? classes.visible : classes.hidden}
      >
        {
          requestMoneyStatus === REQUEST_STATUSES.SUCCEEDED
          &&
          requestMoney.results?.length === 0
            ?
            <div className={classes.notFoundContainer}>
              <img className={classes.notFoundImg} src={notFoundImg} alt="" />
              <p className={classes.textNotFound}>Чаевых пока нет</p>
            </div>
            :
            <EmployeeInfiniteScroll
              dataStatus={requestMoneyStatus}
              color={'gray'}
              getDispatchData={getRequestMoneyEmployee}
              dataSelector={requestMoney}
              specParams={paramsReqMoney}
              hardDep={paramsReqMoney}
              clearData={'requestMoney'}
            />
        }
      </div>
      <div
        className={currentSelect === EOptions.pending ? classes.visible : classes.hidden}
      >
        {
          pendingStatus === REQUEST_STATUSES.SUCCEEDED
          &&
          pending.results?.length === 0
            ?
            <div className={classes.notFoundContainer}>
              <img className={classes.notFoundImg} src={notFoundImg} alt="" />
              <p className={classes.textNotFound}>Чаевых пока нет</p>
            </div>
            :
            <EmployeeInfiniteScroll
              dataStatus={pendingStatus}
              color={'gray'}
              getDispatchData={getRequestMoneyEmployeePending}
              dataSelector={pending}
              specParams={paramsPending}
              hardDep={paramsPending}
              clearData={'pending'}
            />
        }
      </div>
      <div
        className={currentSelect === EOptions.canceled ? classes.visible : classes.hidden}
      >
        {
          canceledStatus === REQUEST_STATUSES.SUCCEEDED
          &&
          canceled.results?.length === 0
            ?
            <div className={classes.notFoundContainer}>
              <img className={classes.notFoundImg} src={notFoundImg} alt="" />
              <p className={classes.textNotFound}>Чаевых пока нет</p>
            </div>
            :
            <EmployeeInfiniteScroll
              dataStatus={canceledStatus}
              color={'gray'}
              getDispatchData={getRequestMoneyEmployeeCanceled}
              dataSelector={canceled}
              specParams={paramsCanceled}
              hardDep={paramsCanceled}
              clearData={'canceled'}
            />
        }
      </div>
    </>
  );
};
export default EmployeeTransactions;
