import {
  ACCESS_STORAGE,
  PATH,
  REFRESH_COOKIE,
  REQUEST_STATUSES,
  USER_TYPE,
} from './constants';
import { useState, useEffect } from 'react';


interface IQueryCases<T> {
  status: T;
  data: T;
  error: T;
  options?: { concat: boolean; mergeResults?: boolean };
}

interface Builder {
  addCase: (actionCreator: string, reducer: (...arg: any) => void) => Builder;
}

const getNestedKey = (obj: any, keyString: string) => {
  const keys = keyString.split('.');
  let value = obj;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[ i ];
    value = value[ key ];
    if (value === undefined) {
      break;
    }
  }
  return value;
};

const splitString = (str: string) => {
  const separator = '.';

  const parts = str.split(separator);
  const allButLast = parts.slice(0, -1).join(separator);
  const last = parts[ parts.length - 1 ];
  return [ allButLast, last ];
};

export const addQueryCases = (
  builder: Builder,
  thunk: any,
  {
    status,
    data,
    error,
    options = { concat: false, mergeResults: false },
  }: IQueryCases<string>,
) => {
  builder
    .addCase(thunk.pending, (state: any) => {
      state[ status ] = REQUEST_STATUSES.REQUESTED;
    })
    .addCase(thunk.fulfilled, (state: any, { payload }: any) => {
      const [ allButLast, last ] = splitString(data);

      const nestedValue: { current: any } = { current: {} };

      if (allButLast) {
        nestedValue.current = getNestedKey(state, allButLast);
      } else {
        nestedValue.current = state;
      }

      state[ status ] = REQUEST_STATUSES.SUCCEEDED;
      if (options.mergeResults) {
        const existingResults: Array<any> = nestedValue.current[ last ]?.results || [];
        const payloadResults: Array<any> = payload.results || [];

        const uniqueResults = new Set([
          // eslint-disable-next-line array-element-newline
          ...existingResults.map((item: any) => item.id),
          ...payloadResults.map((item: any) => item.id),
        ]);


        const uniqueResultsArray = Array.from(uniqueResults).map((id) => {
          const existingItem = existingResults.find(
            (item: any) => item.id === id,
          );
          const payloadItem = payloadResults.find(
            (item: any) => item.id === id,
          );
          return payloadItem || existingItem;
        });

        nestedValue.current[ last ] = {
          ...payload,
          results: uniqueResultsArray,
        };
      } else if (options.concat) {
        if (Array.isArray(payload)) {
          nestedValue.current[ last ] = [
            ...nestedValue.current[ last ], ...payload,
          ];
        }
      } else {
        nestedValue.current[ last ] = payload;
      }
    })
    .addCase(thunk.rejected, (state: any, action: any) => {
      state[ status ] = REQUEST_STATUSES.FAILED;
      state[ error ] = action.error;
    });
};

export const multiStorage = {
  setItem(key: string, value: any) {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  sessionSetItem(key: string, value: any) {
    if (typeof value === 'object') {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
  },
  getItem(key: string) {
    try {
      const localData = JSON.parse(localStorage.getItem(key) as string);
      if (localData) return localData;
      return JSON.parse(sessionStorage.getItem(key) as string);
    } catch (e) {
      const localData = localStorage.getItem(key);
      if (localData) return localData;
      return sessionStorage.getItem(key);
    }
  },
  removeItem(key: string) {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  },
  getAccessToken() {
    return this.getItem('access');
  },
};

interface IValues {
  [key: string]: string;
}
export const cookie = {
  setItem(key: string, value: any, max_age = 2678400) {
    try {
      document.cookie = `${key}=${JSON.stringify(
        value,
      )};max-age=${max_age};secure';path=/`;
    } catch (e) {
      document.cookie = `${key}=${value};max-age=${max_age};secure;path=/`;
    }
  },
  getItem(key: string) {
    const values: IValues = {};
    const cookies = document.cookie.split(/; /);
    for (let i = 0, len = cookies.length; i < len; i++) {
      const cookie = cookies[ i ].split(/=/);
      values[ cookie[ 0 ] ] = cookie[ 1 ];
    }
    try {
      return JSON.parse(values[ key ]);
    } catch (e) {
      return values[ key ];
    }
  },
  getRefreshToken() {
    return this.getItem('refresh');
  },
  removeItem(key: string) {
    document.cookie = `${key}=;max-age=-1;secure;path=/`;
  },
};

export const errorHandler = (error: string): string => {
  let errorMessage = '';
  if (error.includes('must match')) {
    errorMessage = 'Пароли не совпадают';
  }
  if (error.includes('empty')) {
    return 'Заполните поля';
  }
  if (error.includes('Password min')) {
    errorMessage = 'Пароль должен содержать минимум 6 символов';
  }
  if (error.includes('lenght')) {
    errorMessage = 'FIX_ME Пароль должен содержать минимум 6 символов';
  }
  return errorMessage;
};

export const successHandler = (message: string): string => {
  let errorMessage = '';

  if (message.includes('succesfully creatad')) {
    errorMessage = 'Сотрудник был успешно создан';
  }
  if (message.includes('successully patched')) {
    errorMessage = 'Сотрудник был успешно изменен';
  }
  if (message.includes('successully deleted(fired)')) {
    errorMessage = 'Сотрудник был успешно удален';
  }
  if (message.includes('successfully changed')) {
    errorMessage = 'Временный пароль сотрудника был успешно изменен';
  }
  return errorMessage;
};

export const logOut = () => {
  multiStorage.removeItem(REFRESH_COOKIE);
  cookie.removeItem(USER_TYPE);
  multiStorage.removeItem(ACCESS_STORAGE);
  //FIX_ME maybe location to other path
  document.location.href = PATH.main;
};

export const PhoneMaskHelper = (
  value: string,
  name: string,
  setState: React.Dispatch<React.SetStateAction<any>>,
) => {
  const cleaned = value.replace(/[^\d]/g, '');
  if (cleaned.length <= 12) {
    const formattedValue = cleaned
      .replace(
        /^(\d{0,3})(\d{0,3})?(\d{0,2})?(\d{0,2})?(\d{0,2})?/,
        (_, p1, p2, p3, p4, p5) => {
          let result = p1 ? `+${p1}` : '';
          result += p2 ? ` (${p2}` : '';
          result += p3 ? `) ${p3}` : '';
          result += p4 ? `-${p4}` : '';
          result += p5 ? `-${p5}` : '';
          return result;
        },
      )
      .trim();

    name
      ? setState((prevState: any) => ({
        ...prevState,
        [ name ]: formattedValue.trim(),
      }))
      : setState(formattedValue.trim());
  }
};

export const pluralizeReviews = (numReviews: number): string => {
  if (numReviews % 10 === 1 && numReviews % 10 !== 11) {
    return `${numReviews} отзыв`;
  } else if (
    numReviews % 10 >= 2 &&
    numReviews % 10 <= 4 &&
    (numReviews % 100 < 10 || numReviews % 100 >= 20)
  ) {
    return `${numReviews} отзыва`;
  } else {
    return `${numReviews} отзывов`;
  }
};

export const useDebounce = (value: string, delay: number) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [ value, delay ]);

  return debouncedValue;
};
