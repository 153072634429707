import { PolicyShema } from '../type/policyShema';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';


const name = 'policy';

const ENDPOINTS = {
  POLICY: '/api/v1/user-policy',
  CONF_POLICY: '/api/v1/conf-policy',
  PLATFORM: '/api/v1/about-platform',
  AGENT_POLICY: '/api/v1/request-money-policy',
};

export const getUserPolicy = createAsyncThunk(
  `${name}/getPolicy`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.POLICY);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const getPolicy = createAsyncThunk(
  `${name}/getConfPolicy`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.CONF_POLICY);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);
export const getAboutPlatform = createAsyncThunk(
  `${name}/getAboutPlatform`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.PLATFORM);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const getAgentPolicy = createAsyncThunk(
  `${name}/getAgentPolicy`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (_, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.AGENT_POLICY);
      return response.data?.file ?? '';
    } catch (e) {
      return e;
    }
  },
);


const initialState: PolicyShema = {
  policyStatus: REQUEST_STATUSES.NOT_REQUESTED,
  policy: {},
  confPolicy: {},
  platform: {},
  policyError: null,

  agentPolicy: '',
  agentPolicyStatus: REQUEST_STATUSES.NOT_REQUESTED,
  agentError: null,
};

const policySlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getUserPolicy, {
      status: 'policyStatus',
      data: 'policy',
      error: 'policyError',
    });
    addQueryCases(builder, getPolicy, {
      status: 'policyStatus',
      data: 'confPolicy',
      error: 'policyError',
    });
    addQueryCases(builder, getAboutPlatform, {
      status: 'policyStatus',
      data: 'platform',
      error: 'policyError',
    });
    addQueryCases(builder, getAgentPolicy, {
      status: 'agentPolicyStatus',
      data: 'agentPolicy',
      error: 'agentPolicyError',
    });
  },
});


export default policySlice;
