import classes from './EmployeeAccountsCards.module.scss';
import { ETabValues } from '../../EmployeeModal';
import EmployeeAccountCard from '../../../EmployeeAccountCard/EmployeeAccountCard';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import {
  bankAccountSliceActions,
  getListBankAccounts,
  patchPartialUpdateBankAccount,
} from 'reduxApi/slices/bankAccountSlice/model/bankAccountSlice';
import { requestMoneyFormActions } from 'reduxApi/slices/requestMoneyFormSlice/model/requestMoneyFormSlice';
import MbankCard from 'components/Dashboards/Employee/MbankCard/MbankCard';


interface EmployeeAccountsCardsProps {
  setTab: Dispatch<SetStateAction<ETabValues>>
}

const EmployeeAccountsCards: FC<EmployeeAccountsCardsProps> = (props) => {
  const { setTab } = props;
  const dispatch = useAppDispatch();
  const bankAccounts = useAppSelector(state => state.bankAccount.bankAccount);
  const [ selectedCardId, setSelectedCardId ] = useState<number | undefined>();

  useEffect(() => {
    dispatch(getListBankAccounts());
  }, []);

  useEffect(() => {
    if (selectedCardId !== undefined) {
      dispatch(requestMoneyFormActions.handleReplaceValues(bankAccounts?.[ selectedCardId ]));
    }
  }, [ selectedCardId ]);

  const handleEditCard = useCallback((id: string): void => {
    dispatch(bankAccountSliceActions.handleEdit(id));
    setTab(ETabValues.EDIT_CARD);
    setSelectedCardId(undefined);
    dispatch(requestMoneyFormActions.handleCardIsNotSelected());
  }, []);

  const handleEditMbankCard = useCallback((id: string): void => {
    dispatch(bankAccountSliceActions.handleEdit(id));
    setTab(ETabValues.EDIT_MBANK);
    setSelectedCardId(undefined);
    dispatch(requestMoneyFormActions.handleCardIsNotSelected());
  }, []);

  const handleDeleteCard = useCallback(async (id: string) => {
    dispatch(patchPartialUpdateBankAccount({ is_deleted: true, id }))
      .then(() => {
        dispatch(getListBankAccounts());
        setSelectedCardId(undefined);
        dispatch(requestMoneyFormActions.handleCardIsNotSelected());
      });
  }, [ bankAccounts ]);

  return (
    <div className={classes.cards}>
      <div className={classes.employeeCards}>
        <div className={classes.left}>расчетный счет</div>
        <div className={classes.right}
          onClick={() => setTab(ETabValues.SELECT_TYPE_OF_CARD)}
        >+ Добавить счет</div>
      </div>
      <div>
        {
          bankAccounts.map((item, i) => item.type !== 'MBank'
            ?
            <EmployeeAccountCard
              key={item.id}
              selected={selectedCardId === i}
              account_number={item.account_number}
              full_name={item.full_name}
              setSelectId={setSelectedCardId}
              onEdit={handleEditCard}
              onDelete={handleDeleteCard}
              idBack={item.id}
              id={i}
            />
            :
            <MbankCard
              key={item.id}
              phone={item.phone}
              selected={selectedCardId === i}
              setSelectId={setSelectedCardId}
              onEdit={handleEditMbankCard}
              onDelete={handleDeleteCard}
              idBack={item.id}
              id={i}
            />,
          )
        }
      </div>
    </div>
  );
};

export default EmployeeAccountsCards;
