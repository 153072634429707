import classes from './EmployeeAchievements.module.scss';
import completedIcon from 'shared/assets/icons/done_status.svg';
import inProgressIcon from 'shared/assets/icons/progress_done.svg';
import ModalWindow from 'shared/UI/Modal';
import { FC, Dispatch } from 'react';
import { IDreams } from 'reduxApi/slices/employeeSlice/type/employeeSchema';


interface IPropsEmployeeAchievements {
  open: boolean
  setOpen: Dispatch<React.SetStateAction<any>>
  dreams: IDreams[]
}

const EmployeeAchievements: FC<IPropsEmployeeAchievements> = (props) => {
  const { open, setOpen, dreams } = props;
  return (
    <ModalWindow
      open={open}
      setIsModalOpen={setOpen}
      title='История достижений'
      className={classes.achievementsModal}
    >
      <div className={classes.achievements}>
        <div className={classes.achievementsHeader}>
          <div className={classes.achievementsStatus}>
            <p>Состояние</p>
          </div>
          <div className={classes.achievementsTitle}>
            <p>Цель</p>
          </div>
          <div className={classes.achievementsGoal}>
            <p>Достижение</p>
          </div>
        </div>
        {
          dreams && dreams?.map((item, key) => {
            return (
              <div key={key} className={classes.achievementsBlock}>
                <div className={classes.achievementsStatus}>
                  <img src={item.is_finish ? completedIcon : inProgressIcon} alt="status" />
                </div>
                <div className={classes.achievementsTitle}>
                  <p>{item.title}</p>
                </div>
                <div className={classes.achievementsGoal}>
                  <p>{item.goal} <span className={classes.som}>C</span></p>
                </div>
              </div>
            );
          })
        }
      </div>
    </ModalWindow>
  );
};

export default EmployeeAchievements;
