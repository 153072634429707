/* eslint-disable import/named */
import { IAdminPanelState, StorageKey } from '../type/adminPanelTypes';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reduxApi';


const name = 'adminPanel';


export enum ETabValues {
  TRANSACTION = 'TRANSACTION',
  REVIEWS = 'REVIEWS'
}

const adminPanelStorage = JSON.parse(localStorage.getItem(name) as string);


const initialState: IAdminPanelState = {
  isShowEditMoal: false,
  [ StorageKey.SHOWREVIEWS ]: adminPanelStorage?.[ StorageKey.SHOWREVIEWS ]
  ?? false,
  [ StorageKey.CURRENTTAB ]: adminPanelStorage?.[ StorageKey.CURRENTTAB ]
  ?? { id: ETabValues?.REVIEWS, value: 'Чаевые' },
};

const adminPanelSlice = createSlice({
  name,
  initialState,
  reducers: {
    setShowModal(state, action) {
      state.isShowEditMoal = action.payload;
    },
    setChangeToggle(state, action) {
      state[ StorageKey.SHOWREVIEWS ] = action.payload;
      localStorage.setItem(name, JSON.stringify({ ...adminPanelStorage, [ StorageKey.SHOWREVIEWS ]: action.payload }));
    },
    setCurrentTab(state, action) {
      const newValue = action.payload;
      state[ StorageKey.CURRENTTAB ] = newValue;
      localStorage.setItem(name, JSON.stringify({ ...adminPanelStorage, [ StorageKey.CURRENTTAB ]: newValue }));
    },
  },

});

export const { actions: adminPanelActions } = adminPanelSlice;
export const { reducer: adminPanelReducer } = adminPanelSlice;
export default adminPanelSlice;

export const amdinPanelSelect = (state: RootState) => state[ name ];
