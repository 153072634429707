import { ISupportData, ITechSupportState } from '../type/techSupportSchema';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';


const name = 'techSupport';


const ENDPOINTS = { TECH_SUPPORT: '/api/v1/technical-support/' };


export const postSupportRequest = createAsyncThunk(
  `${name}/postSupportRequest`,
  //FIX_ME
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data:ISupportData = { title: '', text: '', contacts: '' }, thunkApi: any) => {
    try {
      const response = await thunkApi.extra.post(ENDPOINTS.TECH_SUPPORT, data);

      return response.data?.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e:any) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);


const initialState: ITechSupportState = {
  techSupportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  techSupport: null,
  techSupportError: null,
};


const techSupportSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, postSupportRequest, {
      status: 'techSupportStatus',
      data: 'techSupport',
      error: 'techSupportError',
    });
  },
});


export default techSupportSlice;
