import { IInstructionsState } from '../type/insturcitonSchema';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';


const name = 'instruction';


const ENDPOINTS = { INSTRUCTIONS: '/api/v1/place/instruction/' };

interface IParams {
  limit: number,
  offset: number
}

export const getInstructions = createAsyncThunk(
  `${name}/getInstructions`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (params: IParams, thunkApi: any) => {
    try {
      const response = await thunkApi.extra.get(ENDPOINTS.INSTRUCTIONS, { params });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e:any) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);


const initialState: IInstructionsState = {
  instructionsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  instructions: { results: [], next: null },
  instructionsError: null,
};


const instructionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getInstructions, {
      status: 'instructionsStatus',
      data: 'instructions',
      error: 'instructionsError',
      options: { concat: false, mergeResults: true },
    });
  },
});


export default instructionSlice;
