import { ReviewShema } from '../type/reviewShema';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQueryCases } from 'shared/common/helpers';
import { REQUEST_STATUSES } from 'shared/common/constants';
import type { PayloadAction } from '@reduxjs/toolkit';


const name = 'review';


const ENDPOINTS = {
  REVIEW_CREATE: '/api/v1/place/review/',
  REVIEW_TEMPLATES: '/api/v1/place/review_template/',
};

export const postReview = createAsyncThunk(
  `${name}/postReview`,
  async (data: FormData, { extra: api }: any) => {
    try {
      const response = await api.post(ENDPOINTS.REVIEW_CREATE, data);
      return response.data;
    } catch (e) {
      return e;
    }
  },
);

export const getReviewTemplates = createAsyncThunk(
  `${name}/getReviewTemplates`,
  async (params: {place_type: string}, { extra: api }: any) => {
    try {
      const response = await api.get(ENDPOINTS.REVIEW_TEMPLATES, { params });
      return response?.data?.results;
    } catch (e) {
      return e;
    }
  },
);

const initialState: ReviewShema = {
  reviewStatus: REQUEST_STATUSES.NOT_REQUESTED,
  review: {},
  reviewTemplates: [],
  reviewError: null,
  stars: {
    point: 0,
    text: '',
  },
};


const reviewSlice = createSlice({
  name,
  initialState,
  reducers: {
    setStars: (state, action: PayloadAction<{point?: number, text?: string}>) => {
      state.stars = { ...state.stars, ...action.payload };
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, postReview, {
      status: 'reviewStatus',
      data: 'review',
      error: 'reviewError',
    });
    addQueryCases(builder, getReviewTemplates, {
      status: 'reviewStatus',
      data: 'reviewTemplates',
      error: 'reviewError',
    });
  },
});

export const { actions: reviewPaymentActions } = reviewSlice;
export const { reducer: reviewPaymentReducer } = reviewSlice;
export default reviewSlice;
