import authSlice from './slices/authSlice/model/authSlice';
import employeeSlice from './slices/employeeSlice/model/employeeSlice';
import employeeListSlice from './slices/employeeListSlice/model/employeeList';
import { employerReducer } from './slices/employersSlice/model/employersSlice';
import paymentSlice from './slices/paymentSlice/model/paymentSlice';
import reviewSlice from './slices/reviewSlice/model/reviewSlice';
import reviewAndTipsSlice from './slices/employeeListSlice/model/reviewSlice';
import bankAccountSlice from './slices/bankAccountSlice/model/bankAccountSlice';
import { requestMoneyFormSlice } from './slices/requestMoneyFormSlice/model/requestMoneyFormSlice';
import { editEmployeeSlice } from './slices/editEmployeeSlice/model/editEmployeeSlice';
import techSupportSlice from './slices/techSupportSlice/model/techSupportSlice';
import instructionSlice from './slices/instructionSlice/model/instructionSlice';
import policySlice from './slices/policySlice/model/policySlice';
import placesSlice from './slices/placesSlice/model/placesSlice';
import partnersSlice from './slices/partnersSlice/model/partnersSlice';
import notificationSlice from './slices/notificationSlice/model/notificationSlice';
import { MbankSlice } from './slices/mbankSlice/model/mbankSlice';
import adminPanelSlice from './slices/adminPanelSlices/model/adminPanel';
import { requester } from '../app/axios/requester';
import { configureStore } from '@reduxjs/toolkit';


export function createReduxStore() {

  return configureStore({
    reducer: {
      auth: authSlice.reducer,
      employee: employeeSlice.reducer,
      employeeList: employeeListSlice.reducer,
      employer: employerReducer,
      review: reviewSlice.reducer,
      payment: paymentSlice.reducer,
      reviewAndTips: reviewAndTipsSlice.reducer,
      bankAccount: bankAccountSlice.reducer,
      requestMoneyForm: requestMoneyFormSlice.reducer,
      editEmployeeForm: editEmployeeSlice.reducer,
      techSupport: techSupportSlice.reducer,
      instruction: instructionSlice.reducer,
      policy: policySlice.reducer,
      places: placesSlice.reducer,
      partners: partnersSlice.reducer,
      notification: notificationSlice.reducer,
      mbank: MbankSlice.reducer,
      adminPanel: adminPanelSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: { extraArgument: requester } }),
  });
}


export const store = createReduxStore();
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
